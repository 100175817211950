<template>
    <div class="kk-textarea">
        <kk-form-title
            :title="label"
            :sub-title="subLabel"
        />
        <div class="textarea-wrapper" :class="{ dashed }">
            <textarea
                v-observe-visibility="onChangeVisibility"
                :value="value"
                :maxlength="maxLength"
                v-bind="$attrs"
                v-on="listeners"
            />
            <span v-if="$slots['bottom-left']" class="bottom-left">
                <slot name="bottom-left" />
            </span>
            <span class="bottom-right">
                <slot name="bottom-right">
                    <span v-if="count" class="limit" v-text="count" />
                </slot>
            </span>
        </div>
    </div>
</template>

<script>
// Polyfill for the intersection observer API that is used by vue-observe-visability
// It is not natively supported by iOS.
import "intersection-observer";

import kkFormTitle from '../kk-form-title/kk-form-title.vue';
import { ObserveVisibility } from 'vue-observe-visibility';

export default {
    name: 'kk-textarea',
    components: {kkFormTitle},

    directives: {
        ObserveVisibility,
    },
    props: {
        value: {
            required: true,
            validator: val => typeof val === 'string' || val === null,
        },

        /**
        * Label/title text for input.
        */
        label: {
            type: String,
            default: '',
        },

        /**
         * Max amount of characters allowed.
         */
        maxLength: {
            default: null,
            type: Number,
        },

        /**
        * sub-Label/sub-title text.
        * Used to describe title
        */
        subLabel: {
            type: String,
            default: '',
        },

        /**
         * Add dashed lines (bg image) to textarea, and border around wrapper
         */
        dashed: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        count() {
            if (this.maxLength === null) {
                return null;
            }

            if (this.value === null) {
                return `0/${this.maxLength}`;
            }

            return `${this.value.length}/${this.maxLength}`;
        },
        listeners() {
            const vm = this;

            return Object.assign({}, this.$listeners, {
                input(event) {
                    vm.$emit('input', event.target.value);
                    vm.reComputeTextareaHeight(event.target);
                },
            });
        },
    },

    methods: {
        onChangeVisibility(isVisible, entry) {
            this.reComputeTextareaHeight(entry.target);
        },

        /**
         * Method used to re-compute the textarea's height.
         * Don't need this to be able to acces the internal scope, thus the «name: (parms) => {}» declaration.
         */
        reComputeTextareaHeight: el => {
            setTimeout(() => {
                el.style.height = el.scrollHeight + 'px';
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.kk-textarea {
    .textarea-wrapper {
        box-sizing: border-box;
        background-color: white;
        position: relative;
        border-radius: 3px;
        border: 1px solid #e0e0e0;
        padding: 10px;
        width: 100%;
        textarea {
            overflow: hidden;
            padding: 0;
            resize: vertical;
            width: 100%;
            border: 0;
            font-size: 1em;
            min-height: 43px;

            &:disabled {
                -webkit-text-fill-color: #c6c8ca;
                opacity: 1;
            }
        }

        &.dashed {
            textarea {
                background: url(/images/bg-textarea.png) repeat 0 -5px;
                line-height: 30px;
                color: #696969;
            }
        }

        .bottom-left,
        .bottom-right {
            position: absolute;
            bottom: 0;
            font-size: 12px;
            color: #C6C8CA;
        }
        .bottom-right {
            right: 10px;
        }
        .bottom-left {
            left: 10px;
        }
    }
}
</style>
