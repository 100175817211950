import Pica from 'pica/dist/pica';
import "./piexif.js";

/**
 * image-file.js - operations on image files.
 */
export default {
    blobToDataURL,
    dataURLToBlob,
    dataURLToImg,
    imgToCanvas,
    canvasToDataURL,
    canvasToBlob,
    getExifObj,
    getExifOrientation,
    deleteExifOrientation,
};

/**
 * Load Blob --> DataURL
 *
 * @param {File} from image file @see https://developer.mozilla.org/en-US/docs/Web/API/File 07.11.18
 *
 * @return {Promise} DataURL
 */
export async function blobToDataURL(from) {
    let blob = from;

    return new Promise(resolve => {
        let reader = new FileReader();

        reader.onload = event => {
            resolve(event.target.result);
        };
        reader.readAsDataURL(blob);
    });
}

/**
 * Convert canvas pixels to dataURL
 *
 * @param {Object} from HTML <canvas>
 * @param {Object} exifobj optional metadata for jpeg/tiff as exifobj
 * @param {String} type defaults to 'image/jpeg'
 * @param {Number} quality defaults to 0.9
 *
 * @return {Promise} of dataURL
 */
export async function canvasToDataURL(from, {exifobj = null, type = 'image/jpeg', quality = 0.9} = {}) {
    return new Promise(resolve => {
        let dataURL = from.toDataURL(type, quality);

        if (exifobj) {
            let exifstr = piexifjs.dump(exifobj);
            dataURL = piexifjs.insert(exifstr, dataURL);
        }

        return resolve(dataURL);
    });
}

/**
 * Convert canvas pixels to a new Blob
 *
 * @param {Object} from HTML <canvas>
 * @param {String} type defaults to 'image/jpeg'
 * @param {Number} quality defaults to 0.9
 *
 * @return {Promise} of Blob
 */
export async function canvasToBlob(from, {type = 'image/jpeg', quality = 0.9} = {})  {
    const pica = Pica( {features: [ 'js', 'wasm', 'ww', 'cib' ]});

    return pica.toBlob(from, type, quality);
}

/**
 * Load dataURL --> <img>
 *
 * @param {String} from dataURL base64
 *
 * @return {Promise} HTML <img>
 */
export async function dataURLToImg(from) {
    let fromDataURL = from;

    return new Promise((resolve, reject) => {
        let img = new Image();

        img.onload = () => {
            resolve(img);
        };

        if (!fromDataURL) {
            reject();
        }
        img.src = fromDataURL;
    });
}

/**
 * Convert base64 dataURL --> Blob
 * @see http://eugeneware.com/software-development/converting-base64-datauri-strings-into-blobs-or-typed-array 15.11.18
 *
 * @param {String} dataURL base64 image/*
 * @param {String} type defaults to 'image/jpeg'
 *
 * @return {Promise} of Blob containing image
 */
export async function dataURLToBlob(dataURL, { type = 'image/jpeg' } = {}) {
    return new Promise(resolve => {
        const BASE64_MARKER = ';base64,';
        let base64Index = dataURL.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        let base64 = dataURL.substring(base64Index);
        let raw = window.atob(base64);
        let rawLength = raw.length;
        let array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        resolve(new Blob([array], { type }));
    });
}

/**
 * Draw <img> on top of canvas.
 * @see https://stackoverflow.com/a/40867559/1223692 31.10.18
 *
 * @param {Object} from HTML <img>
 * @param {Object} to HTML <canvas>
 * @param {Number} exifOrientation integer between [-2, 8] from EXIF
 *
 * @return {Promise} of canvas with image drawn on top
 */
export async function imgToCanvas(from, to, {exifOrientation = null} = {}) {
    let img = from;
    let outCanvas = to;

    let picaCanvas = document.createElement('canvas');
    let { width, height } = _calcTargetSize(img);
    picaCanvas.width = width;
    picaCanvas.height = height;

    const pica = Pica( {features: [ 'js', 'wasm', 'ww', 'cib' ]});

    return pica.resize(img, picaCanvas).then(canvasResized => {
        let outCtx = outCanvas.getContext('2d', {alpha: false});

        // set proper canvas dimensions before transform & export
        if (exifOrientation && 4 < exifOrientation && exifOrientation < 9) {
            outCanvas.width = height;
            outCanvas.height = width;
        } else {
            outCanvas.width = width;
            outCanvas.height = height;
        }

        // transform context before drawing image
        switch (exifOrientation) {
            case 2: outCtx.transform(-1, 0, 0, 1, width, 0); break;
            case 3: outCtx.transform(-1, 0, 0, -1, width, height); break;
            case 4: outCtx.transform(1, 0, 0, -1, 0, height); break;
            case 5: outCtx.transform(0, 1, 1, 0, 0, 0); break;
            case 6: outCtx.transform(0, 1, -1, 0, height, 0); break;
            case 7: outCtx.transform(0, -1, -1, 0, height, width); break;
            case 8: outCtx.transform(0, -1, 1, 0, 0, width); break;
            default: break;
        }
        outCtx.drawImage(canvasResized, 0, 0);
    }, err => {
        console.error('Image resize failed', err);
    });
}

/**
 * Deletes the exif orientation if it exists in the passed exifobj.
 *
 * @param {Object} exifobj returned from piexifjs.load
 */
export function deleteExifOrientation(exifobj) {
    if (!exifobj) {
        return;
    }

    if (exifobj['0th'][piexifjs.ImageIFD.Orientation]) {
        delete exifobj['0th'][piexifjs.ImageIFD.Orientation];
    }
}


/**
 * Read EXIF obj from DataURL
 *
 * @param {String} from dataURL base64
 *
 * @return {Promise} null if error, else exifobj
 */
export function getExifObj(from) {
    let exifobj = null;

    try {
        exifobj = piexifjs.load(from);
    } catch (e) {
        return null;
    }

    return exifobj;
}

/**
 * Get exif orientation 2,3,4,5...8
 *
 * @param from exifobj
 *
 * @return null if error, else return exif orientation
 */
export function getExifOrientation(from) {
    if (!from) {
        return null;
    }
    let orientation = from["0th"][piexifjs.ImageIFD.Orientation];

    if (!orientation) {
        return null;
    }

    return orientation;
}

/**
 * Calculate the size of the image if scaled to a max resolution of 2540x1440
 * If Image has resolution 2541x1440, resulting canvas should be 2540x1439.
 *
 * @param {Object} from <img> element is candidate for downscaling
 *
 * @return {Object} {width, height} size
 */
function _calcTargetSize(from) {
    const imgWidth = from.width;
    const imgHeight = from.height;
    const [widthRatio, heightRatio] = (() => {
        if (imgWidth > imgHeight) {
            return [2540 / imgWidth, 1440 / imgHeight];
        } else if (imgWidth <= imgHeight) {
            return [1440 / imgWidth, 2540 / imgHeight];
        }
    })();

    if (widthRatio >= 1.0 && heightRatio >= 1.0) {
        return {
            width: imgWidth,
            height: imgHeight,
        };
        // .. Keep resolution if image is less than max resolution
    }

    if (widthRatio < heightRatio) {
        return { width: imgWidth * widthRatio, height: imgHeight * widthRatio};
    } else if (widthRatio >= heightRatio) {
        return { width: imgWidth * heightRatio, height: imgHeight * heightRatio};
    }
}
