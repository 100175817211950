import {LangMocker} from './lang-mocker';

// When testing components locally Lang is not defined,
// It is an external dependency injected into kk-vue-components.
// LangMocker just returns whatever key you put into it.

if (typeof window.Lang === 'undefined') {
    window.Lang = LangMocker;
}
import {
    trans as transFn,
    dotdotdot,
    lowercase,
    uppercase,
} from '../filters/';

export const trans =  {
    filters: {
        trans: transFn,
        lowercase,
        uppercase,
        dotdotdot,
    },

    methods: {
        trans: transFn,
    },
};
