export const namespace = 'toast';
export const TOAST_STORE = {
    STATE: {
        TOASTS: 'toasts',
    },
    MUTATIONS: {
        ADD_TOAST: 'ADD_TOAST',
        REMOVE_TOAST: 'REMOVE_TOAST',
    },
    ACTIONS: {
        CREATE_TOAST: 'createToast',
        CREATE_INFO_TOAST: 'createInfoToast',
        CREATE_SUCCESS_TOAST: 'createSuccessToast',
        CREATE_WARNING_TOAST: 'createWarningToast',
        CREATE_DANGER_TOAST: 'createDangerToast',
    },
};

function initialState() {
    return {
        [TOAST_STORE.STATE.TOASTS]: [],
    };
}

const mutations = {
    [TOAST_STORE.MUTATIONS.ADD_TOAST]: (state, toast) => {
        state[TOAST_STORE.STATE.TOASTS].unshift(toast);
    },
    [TOAST_STORE.MUTATIONS.REMOVE_TOAST]: (state, id) => {
        const index = state[TOAST_STORE.STATE.TOASTS].findIndex(toast => toast.id === id);

        if (index !== -1) {
            state[TOAST_STORE.STATE.TOASTS].splice(index, 1);
        }
    },
};

const actions = {
    /**
     * Create a generic toast, is info by default but can be overridden
     */
    [TOAST_STORE.ACTIONS.CREATE_TOAST]: ({ commit }, {
        text = '',
        subText = '',
        type = 'info',
        layout = 'toast',
        closeButtonVisible = true,
        duration = 3000,
    }) => {
        const id = new Date().getTime();
        const toast = {
            id,
            text,
            subText,
            type,
            layout,
            closeButtonVisible,
        };

        commit(TOAST_STORE.MUTATIONS.ADD_TOAST, toast);

        setTimeout(() => {
            commit(TOAST_STORE.MUTATIONS.REMOVE_TOAST, id);
        }, duration);
    },

    /**
     * Create an info toast
     */
    [TOAST_STORE.ACTIONS.CREATE_INFO_TOAST]: ({ commit }, {
        text = '',
        subText = '',
        layout = 'toast',
        closeButtonVisible = true,
        duration = 3000,
    }) => {
        const id = new Date().getTime();
        const toast = {
            id: new Date().getTime(),
            text,
            subText,
            type: 'info',
            layout,
            closeButtonVisible,
        };

        commit(TOAST_STORE.MUTATIONS.ADD_TOAST, toast);

        setTimeout(() => {
            commit(TOAST_STORE.MUTATIONS.REMOVE_TOAST, id);
        }, duration);
    },

    /**
     * Create a success toast
     */
    [TOAST_STORE.ACTIONS.CREATE_SUCCESS_TOAST]: ({ commit }, {
        text = '',
        subText = '',
        layout = 'toast',
        closeButtonVisible = true,
        duration = 3000,
    }) => {
        const id = new Date().getTime();
        const toast = {
            id,
            text,
            subText,
            type: 'success',
            layout,
            closeButtonVisible,
        };

        commit(TOAST_STORE.MUTATIONS.ADD_TOAST, toast);

        setTimeout(() => {
            commit(TOAST_STORE.MUTATIONS.REMOVE_TOAST, id);
        }, duration);
    },

    /**
     * Create a warning toast
     */
    [TOAST_STORE.ACTIONS.CREATE_WARNING_TOAST]: ({ commit }, {
        text = '',
        subText = '',
        layout = 'toast',
        closeButtonVisible = true,
        duration = 3000,
    }) => {
        const id = new Date().getTime();
        const toast = {
            id,
            text,
            subText,
            type: 'warning',
            layout,
            closeButtonVisible,
        };

        commit(TOAST_STORE.MUTATIONS.ADD_TOAST, toast);

        setTimeout(() => {
            commit(TOAST_STORE.MUTATIONS.REMOVE_TOAST, id);
        }, duration);
    },

    /**
     * Create a danger toast
     */
    [TOAST_STORE.ACTIONS.CREATE_DANGER_TOAST]: ({ commit }, {
        text = '',
        subText = '',
        layout = 'toast',
        closeButtonVisible = true,
        duration = 3000,
    }) => {
        const id = new Date().getTime();
        const toast = {
            id,
            text,
            subText,
            type: 'danger',
            layout,
            closeButtonVisible,
        };

        commit(TOAST_STORE.MUTATIONS.ADD_TOAST, toast);

        setTimeout(() => {
            commit(TOAST_STORE.MUTATIONS.REMOVE_TOAST, id);
        }, duration);
    },
};

export const store = {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
};
