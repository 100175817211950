/* eslint-disable no-prototype-builtins */
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentAltDots } from '@fortawesome/pro-light-svg-icons/faCommentAltDots';
import { faHammer } from '@fortawesome/pro-light-svg-icons/faHammer';
import { faMobileAlt } from '@fortawesome/pro-light-svg-icons/faMobileAlt';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faSms } from '@fortawesome/pro-light-svg-icons/faSms';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faExclamationTriangle} from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faQuestionCircle} from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import kkButton from '../kk-button/kk-button.vue';
import kkContacts from '../kk-contacts/kk-contacts.vue';
import kkModal from '../kk-modal/kk-modal.vue';
import kkPopupDesktop from '../kk-popup-desktop/kk-popup-desktop.vue';
import vueSelect from 'vue-select';
import ks from "../../lib/ks.js";
import { textarea } from '../../mixin/textarea.js';
import { trans } from "../../mixin/trans.js";
import svgIcon from '../svg-icon/svg-icon.vue';
import { PhoneNumber, PhoneNumberType } from '../../lib/phoneNumber.js';
import * as GsmCharsetUtils from '@trt2/gsm-charset-utils';
import kkTooltip from '../kk-tooltip/kk-tooltip.vue';

library.add(
    faCommentAltDots,
    faUser,
    faUsers,
    faMobileAlt,
    faHammer,
    faTimes,
    faPaperPlane,
    faLink,
    faSms,
    faExclamationTriangle,
    faQuestionCircle,
    faChevronDown,
);

// @vue/component
export default {
    name: "kk-sms-dialog",

    components: {
        svgIcon,
        kkModal,
        kkContacts,
        kkButton,
        kkPopupDesktop,
        FontAwesomeIcon,
        kkTooltip,
        vueSelect,
    },

    mixins: [trans, textarea],
    props: {
        /**
         * The selectable sender-names. Typically you get these from /api/senders
         */
        senderNames: {
            type: Array,
            default: function() {
                return [];
            },
        },
        /**
         * The contacts array
         */
        contacts: {
            type: Array,
            default: function() {
                return [];
            },
        },
        /**
         * The array of available teams to send to (each will be sent as a group-sms). Get this from api/addressbook
         */
        teams: {
            type: Array,
            default: function() {
                return [];
            },
        },
        /**
         * The array of available positions to send to (each will be sent as a group-sms). Get this from api/addressbook
         */
        positions: {
            type: Array,
            default: function() {
                return [];
            },
        },
        /**
         * The array of available employees to send to. Get this from api/addressbook
         */
        employees: {
            type: Array,
            default: function() {
                return [];
            },
        },
        /**
         * An array of already selected contacts when opening the SMS Dialog.
         * These contacts must be in the following format:
         * [{type:string(contactId|employeeId|mobilenumber|teamName|positionName), value:String}]
         */
        selectedContacts: {
            type: Array,
            default: function() {
                return [];
            },
            validator: selectedContacts => selectedContacts.every(({type, value}) => {
                return [
                    'contactId',
                    'employeeId',
                    'mobilenumber',
                    'teamName',
                    'positionName',
                ].includes(type) && typeof value === 'string';
            }),
        },
        /**
         * A string containing a default message that will appear when the SMS Dialog opens.
         */
        message: {
            type: String,
            default: "",
        },
        /**
         * A string containing a link to something.
         */
        link: {
            type: String,
            default: "",
        },

        /**
         * The selected sendername when the dialog opens. The selected sendername should be cached
         * to prevent the user from selecting sendername on each send.
         * Typically you get the default sender from api/sms [defaultSender.id]
         * Typically you post the selected sender to api/sms/userdefault/{id} to update the setting.
         */
        selectedSendername: {
            type: Number,
            default: null,
        },
        /**
         * The error object is typically returned as a result from posting to api/sms/send.
         * The object holds receivers that did not receive the message along with other information about the SMS.
         * When set, the dialog shows and the receivers that had an error is red.
         */
        errorObject: {
            type: Object,
            default: null,
        },

        redirectToOnClick: {
            type: String,
            default: "",
        },

    },

    data() {
        return {
            modalOpen: false,
            smsObj: {
                senderName: null,
                message: "",
                receivers: [],
                link: "",
                charCount: 0,
                msgCount: 1,
                charsPerSegment: 160,
                encoding: "GSM",
                warnings: {
                    showSpecialCharWarn: false,
                    showMaxTenWarn: false,
                    showExceededMsg: false,
                },
            },
            manualContactInput: "",
            showRecipientsPopup: false,
            selectedRecipient: {
                recipients: [],
            },
        };
    },
    computed: {
        countChars() {
            let single_chars = [
                10, 13, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
                43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
                56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
                69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81,
                82, 83, 84, 85, 86, 87, 88, 89, 90, 95, 97, 98, 99,
                100, 101, 102, 103, 104, 105, 106, 107, 108, 109,
                110, 111, 112, 113, 114, 115, 116, 117, 118, 119,
                120, 121, 122, 161, 163, 164, 165, 167, 191, 196,
                197, 198, 199, 201, 209, 214, 216, 220, 223, 224,
                228, 229, 230, 232, 233, 236, 241, 242, 246, 248,
                249, 252, 915, 916, 920, 923, 926, 928, 931, 934,
                936, 937,
            ];

            // SMS 2-count chars (GSM-7 extension table)
            let multi_chars = [12, 91, 92, 93, 94, 123, 124, 125, 126, 8364];

            let count = 0;
            let message = this.smsObj.message + this.smsObj.link;

            for (let char of message) {
                let code = char.charCodeAt(0);

                if (single_chars.includes(code)) {
                    count ++;
                } else if (multi_chars.includes(code)) {
                    count += 2;
                } else {
                    count ++;
                }
            }

            return count;
        },

        totalEmployeesWithMobile() {
            let numbers = [];

            for (let employee of this.employees) {
                if (employee.mobile !== undefined && employee.mobile !== null && employee.mobile !== "") {
                    numbers.push(employee.mobile);
                }
            }

            return numbers.length;
        },

        totalRecipients() {
            let total = 0;
            let recipients = [];
            let allEmployeesDetected = this.smsObj.receivers.find(obj => {
                return obj.mobile === "+A000";
            });

            if (allEmployeesDetected) {
                //Subtract 1 because "All employees" is also a contact
                total += this.totalEmployeesWithMobile - 1;
            }

            for (let contact of this.smsObj.receivers) {
                if (contact.type === "team" || contact.type === "position" && !allEmployeesDetected) {
                    for (let person of contact.recipients) {
                        const exists = recipients.find(obj => {
                            return obj.mobile === person.mobile;
                        });

                        if (!exists) {
                            recipients.push(person);
                            total ++;
                        }
                    }
                } else {
                    if (
                        allEmployeesDetected
                        && (contact.type === "employee" || contact.type === "team" || contact.type === "position")
                    ) {
                        continue;
                    }
                    const exists = recipients.find(obj => {
                        return obj.mobile === contact.mobile;
                    });

                    if (!exists) {
                        recipients.push(contact);
                        total ++;
                    }
                }
            }

            return total;
        },

        uniqueRecipients() {
            let recipients = [];

            for (let contact of this.smsObj.receivers) {
                if (contact.type === "team" || contact.type === "position") {
                    for (let person of contact.recipients) {
                        let exists = recipients.find(obj => {
                            return obj.mobile === person.mobile;
                        });

                        if (!exists) {
                            recipients.push(person);
                        }
                    }
                } else {
                    let exists = recipients.find(obj => {
                        return obj.mobile === contact.mobile;
                    });

                    if (!exists) {
                        //Push contact from this.allContacts to get all information.
                        //kkContactSelector only returns some data which is insufficient
                        let existingContact = this.getContact(contact.mobile, contact.type);

                        if (existingContact) {
                            recipients.push(existingContact);
                        } else {
                            recipients.push(contact);
                        }
                    }
                }
            }

            return recipients;
        },

        receiversList() {
            let recipients = [];

            let allEmployeesDetected = this.smsObj.receivers.find(obj => {
                return obj.mobile === "+A000";
            });

            if (allEmployeesDetected) {
                recipients.push({
                    "personId": "employee4",
                    "contactId": 0,
                    "name": "-- Alle ansatte--",
                    "mobile": "+A000",
                    "type": "employee",
                    "contactName": "Ansatte",
                    "recipients": [],
                });
            }

            for (let contact of this.smsObj.receivers) {
                if (contact.type !== 'contact' && contact.type !== 'person' && allEmployeesDetected) {
                    continue;
                }
                let exists = recipients.find(obj => {
                    const equalId = obj.personId === contact.personId && obj.personId > 0;
                    const equalPhone = obj.mobile === contact.mobile && obj.mobile > 0;

                    return equalId || equalPhone;
                });

                if (!exists && !this.existsInGroup(contact)) {
                    recipients.push(contact);
                }
            }

            return recipients;
        },

        countEmployees() {
            return this.employees.length;
        },

        receiversPopupRect() {
            let empty = {left: 0, top: 0};

            if (this.selectedRecipient && this.selectedRecipient.personId) {
                let tagPos = this.getPos(this.selectedRecipient);

                if (!tagPos) {
                    return empty;
                }

                let position = {
                    left: tagPos.left + tagPos.width / 2,
                    top: tagPos.top + tagPos.height,
                };

                return position;
            }

            return empty;
        },

        messagesByChars() {
            return Math.ceil(this.smsObj.charCount / this.smsObj.charsPerSegment);
        },

        totalMessages() {
            return Math.ceil(this.smsObj.charCount / this.smsObj.charsPerSegment) * this.totalRecipients;
        },

        refactoredTeams() {
            let id = 1;

            if (this.teams.length === 0) {
                return null;
            }

            let teamsObject = {
                id: 2,
                personId: 2,
                contactId: 2,
                name: Lang.get('sms-dialog.Team'),
                email: '',
                mobile: '',
                type: 0,
                persons: [],
                category: Lang.get('sms-dialog.Team'),
                sortFirst: true,
            };

            for (let team of this.teams) {
                let newObj = {
                    id: "team" + id,
                    personId: "team" + id,
                    contactId: 2,
                    name: team.name,
                    email: '',
                    mobile: '0',
                    type: 'team',
                    persons: [],
                };
                id ++;
                teamsObject.persons.push(newObj);
            }

            return teamsObject;
        },
        refactoredPositions() {
            let id = 1;

            if (this.positions.length === 0) {
                return null;
            }

            let positionsObject = {
                id: 1,
                personId: 1,
                contactId: 1,
                name: Lang.get('sms-dialog.Stillinger'),
                email: '',
                mobile: '',
                type: 0,
                persons: [],
                category: Lang.get('sms-dialog.Stillinger'),
                sortFirst: true,
            };

            for (let position of this.positions) {
                let newObj = {
                    id: "position" + id,
                    personId: "position" + id,
                    contactId: 1,
                    name: position.name,
                    email: '',
                    mobile: '0',
                    type: 'position',
                    persons: [],
                };
                id ++;
                positionsObject.persons.push(newObj);
            }

            return positionsObject;
        },

        refactoredEmployees() {
            let id = 1;

            if (this.employees.length === 0) {
                return null;
            }

            let employeesObject = {
                id: 0,
                personId: 0,
                contactId: 0,
                name: Lang.get('sms-dialog.Ansatte'),
                email: '',
                mobile: '',
                type: 0,
                persons: [],
                category: Lang.get('sms-dialog.Ansatte'),
                sortFirst: true,
            };

            for (let employee of this.employees) {
                let newObj = {
                    id: "employee" + id,
                    personId: "employee" + id,
                    employeeId: employee.id,
                    contactId: 0,
                    name: employee.name,
                    email: employee.email,
                    mobile: employee.mobile,
                    type: 'employee',
                    persons: [],
                    category: Lang.get('sms-dialog.Ansatte'),
                    position: employee.position,
                    team: employee.team,
                };
                id ++;
                employeesObject.persons.push(newObj);
            }

            return employeesObject;
        },

        refactoredContacts() {
            let id = 1;
            let personId = 1;
            let contacts = [];

            for (let contact of this.contacts) {
                let newObj = {
                    id: "contact" + id,
                    personId: "contact" + id,
                    contactId: contact.id,
                    name: contact.name,
                    email: contact.email,
                    mobile: contact.mobile,
                    type: 'contact',
                    persons: [],
                    category: Lang.get('sms-dialog.Kontakter'),
                    contact_id: contact.id,
                };
                contacts.push(newObj);

                for (let person of contact.persons) {
                    let newPerson = {
                        id: "person" + personId,
                        personId: "person" + personId,
                        contactId: id,
                        name: person.name,
                        email: person.email,
                        mobile: person.mobile,
                        type: 'person',
                        persons: [],
                        category: Lang.get('sms-dialog.Kontakter'),
                        contact_person_id: person.id,
                        contact_id: contact.id,
                    };
                    newObj.persons.push(newPerson);
                    personId ++;
                }

                id ++;
            }

            return contacts;
        },
        allContacts() {
            let all = [];

            if (this.refactoredTeams !== null) {
                all.push(this.refactoredTeams);
            }

            if (this.refactoredPositions !== null) {
                all.push(this.refactoredPositions);
            }

            if (this.refactoredEmployees !== null) {
                all.push(this.refactoredEmployees);
            }

            all.push(...this.refactoredContacts);

            return all;
        },

        /**
         * Find all unique category names from the contact list.
         */
        categories() {
            return [...new Set(this.allContacts.map(c => c.category))];
        },
    },

    watch: {
        modalOpen() {
            // Runs getCharCount once when the modal has been opened.
            // It runs too soon without the setTimeout

            if (this.modalOpen) {
                setTimeout(() => {
                    this.getCharCount();
                }, 500);
            }
        },

        'smsObj.message'() {
            this.removeIllegalChars();
            this.getCharCount();
            this.handleSmsWarnings();
        },

        'smsObj.senderName'() {
            this.$emit('select-sendername', this.smsObj.senderName);
        },
        message() {
            this.smsObj.message = this.message;
        },
        'selectedContacts'() {
            this.transferSelectedContacts();
        },
        'selectedSendername'() {
            if (this.selectSendername !== null) {
                this.smsObj.senderName = this.selectedSendername;
            }
        },
        'errorObject'() {
            this.handleReceiverErrors(this.errorObject);
        },
        'link'() {
            this.smsObj.link = this.link;
        },
        'smsObj.receivers'() {
            this.$nextTick(() => {
                let container = this.$el.querySelector(".receivers-component");

                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
    },

    created() {
        if (this.selectSendername !== null) {
            this.smsObj.senderName = this.selectedSendername;
        }
        this.smsObj.link = this.link;
        this.smsObj.message = this.message;
    },

    methods: {
        handleSmsWarnings() {
            // Shows the smsObj warnings accordingly

            // If GSM or USC2 Encoding
            if (this.smsObj.encoding !== "GSM") {
                this.smsObj.warnings.showSpecialCharWarn = true;
            } else {
                this.smsObj.warnings.showSpecialCharWarn = false;
            }

            // If messages are 10 or above
            if (this.smsObj.msgCount >= 10) {
                this.smsObj.warnings.showMaxTenWarn = true;
            } else {
                this.smsObj.warnings.showMaxTenWarn = false;
            }

            // If user has exceeded 15 messages
            if (this.smsObj.msgCount > 15) {
                this.smsObj.warnings.showExceededMsg = true;
            } else {
                this.smsObj.warnings.showExceededMsg = false;
            }
        },

        /**
         * Use the gsm-charset-utils library to get the charcount and msg count.
         * This library can also be used to remove non GSM characters if needed in the future
         * @see https://bit.ly/3g5KLV3
         */
        getCharCount() {
            const {
                charCount,
                msgCount,
                charsPerSegment,
                encoding,
            } = GsmCharsetUtils.getCharCount(this.smsObj.message + this.smsObj.link);
            this.smsObj.charCount = charCount;
            this.smsObj.msgCount = msgCount;
            this.smsObj.charsPerSegment = charsPerSegment;
            this.smsObj.encoding = encoding;
        },

        removeIllegalChars() {
            this.smsObj.message = this.smsObj.message
                .replace(/\u00AD|\u2013|\u2014|\u2022/g, "-") // – | — | •
                .replace(/\u2026/g, "...") // …
                .replace(/\u00BB|\u00AB|\u201E|\u201D|\u201C/g, `"`) // » | « | „ | ” | “
                .replace(/\u201A|\u2019|\u2018|\u0060|\u00B4/g, "'") // ‚ | ’ | ‘ | ` | ´
                .replace(/\u0104|\u0100/g, "A") // Ą | Ā
                .replace(/\u0105|\u0101/g, "a") // ą | ā
                .replace(/\u0106|\u010C/g, "C") // Ć | Č
                .replace(/\u0107|\u010D/g, "c") // ć | č
                .replace(/\u0118|\u0112|\u0116/g, "E") // Ę | Ē | Ė
                .replace(/\u0119|\u0113|\u0117/g, "e") // ę | ē | ė
                .replace(/\u0122/g, "G") // Ģ
                .replace(/\u0123/g, "g") // ģ
                .replace(/\u012A|\u012E/g, "I") // Ī | Į
                .replace(/\u012B|\u012F/g, "i") // ī | į
                .replace(/\u0136/g, "K") // Ķ
                .replace(/\u0137/g, "k") // ķ
                .replace(/\u0141|\u013B/g, "L") // Ł | Ļ
                .replace(/\u0142|\u013C/g, "l") // ł | ļ
                .replace(/\u0143|\u0145/g, "N") // Ń | Ņ
                .replace(/\u0144|\u0146/g, "n") // ń | ņ
                .replace(/\u00D3|\u00D5/g, "O") // Ó | Õ
                .replace(/\u00F3|\u00F5/g, "o") // ó | õ
                .replace(/\u015A|\u0160/g, "S") // Ś | Š
                .replace(/\u015B|\u0161/g, "s") // ś | š
                .replace(/\u016A|\u0172/g, "U") // Ū | Ų
                .replace(/\u016B|\u0173/g, "u") // ū | ų
                .replace(/\u0179|\u017B|\u017D/g, "Z") // Ź | Ż | Ž
                .replace(/\u017A|\u017C|\u017E/g, "z") // ź | ż | ž
                // eslint-disable-next-line no-control-regex
                .replace(/\u000B|\u00A0|\u0009/g, " "); // LINE TABULATION | NO-BREAK SPACE | CHARACTER TABULATION
        },

        getLang(string) {
            return Lang.get(string);
        },

        receiverExists(mobile) {
            if (!(mobile instanceof PhoneNumber)) {
                mobile = new PhoneNumber(mobile, 47);
            }

            for (let contact of this.smsObj.receivers) {
                if (contact.type === "team" || contact.type === "position") {
                    const person = contact.recipients.find(person => {
                        const personPhone = new PhoneNumber(person.mobile, 47);

                        if (mobile.equals(personPhone)) {
                            return true;
                        }

                        return false;
                    });

                    if (person !== undefined) {
                        return true;
                    }
                } else {
                    let contactPhone = new PhoneNumber(contact.mobile, 47);

                    if (contactPhone.equals(mobile)) {
                        return true;
                    }
                }
            }

            return false;
        },

        getContact(mobile, type) {
            if (type === 'employee') {
                for (let employee of this.refactoredEmployees.persons) {
                    if (employee.mobile === mobile) {
                        return employee;
                    }
                }

                return null;
            }

            if (type === 'contact') {
                for (let contact of this.refactoredContacts) {
                    if (contact.mobile === mobile) {
                        return contact;
                    }
                }

                return null;
            }

            if (type === 'person') {
                for (let contact of this.refactoredContacts) {
                    for (let person of contact.persons) {
                        if (person.mobile === mobile) {
                            return person;
                        }
                    }
                }

                return null;
            }

            return null;
        },

        addContact(pre) {
            let inserted = false;

            if (this.receiverExists(pre.value)) {
                ks.toast("Fjernet mottaker som allerede er lagt til");
                this.manualContactInput = '';

                return;
            }

            if (pre.type === 'contactId') {
                for (let contact of this.allContacts) {
                    if (
                        contact !== null
                        && contact.hasOwnProperty('contactId')
                        && contact.contactId === parseInt(pre.value)
                        && contact.type === 'contact'
                    ) {
                        this.smsObj.receivers.push(contact);
                        contact.mobile = this.getNumberObject(contact.mobile).toString();
                        inserted = true;
                    }
                }
            }

            if (pre.type === 'employeeId') {
                for (let contact of this.allContacts) {
                    if (
                        contact !== null
                        && contact.hasOwnProperty('employeeId')
                        && contact.employeeId === parseInt(pre.value)
                        && contact.type === 'employee'
                    ) {
                        this.smsObj.receivers.push(contact);
                        contact.mobile = this.getNumberObject(contact.mobile).toString();
                        inserted = true;

                        return;
                    }

                    if (!(contact.hasOwnProperty('persons'))) {
                        continue;
                    }

                    for (let person of contact.persons) {
                        if (
                            person.hasOwnProperty('employeeId')
                            && parseInt(person.employeeId) === parseInt(pre.value)
                        ) {
                            this.smsObj.receivers.push(person);
                            person.mobile = this.getNumberObject(person.mobile).toString();

                            return;
                        }
                    }
                }
            }

            if (pre.type === 'mobilenumber') {
                for (let contact of this.allContacts) {
                    if (contact !== null && contact.hasOwnProperty('mobile') && contact.mobile === pre.value) {
                        this.smsObj.receivers.push(contact);
                        contact.mobile = this.getNumberObject(contact.mobile).toString();
                        inserted = true;
                    } else {
                        if (contact === null || !contact.hasOwnProperty('persons')) {
                            continue;
                        }

                        for (let person of contact.persons) {
                            if (person.hasOwnProperty('mobile') && person.mobile === pre.value) {
                                this.smsObj.receivers.push(person);
                                person.mobile = this.getNumberObject(person.mobile).toString();
                                inserted = true;
                            }
                        }
                    }
                }

                if (!inserted) {
                    let id = Math.floor((Math.random() * 10000000) + 10000000000);
                    let contact = {personId: "M" + id, name: "", mobile: this.getNumberObject(pre.value).toString()};
                    this.smsObj.receivers.push(contact);
                }
            }

            if (pre.type === 'teamName') {
                for (let contact of this.allContacts) {
                    if (contact !== null && contact.type === 'team' && contact.name === pre.value) {
                        this.smsObj.receivers.push(contact);
                        inserted = true;
                    }
                }
            }

            if (pre.type === 'positionName') {
                for (let contact of this.allContacts) {
                    if (contact !== null && contact.type === 'position' && contact.name === pre.value) {
                        this.smsObj.receivers.push(contact);
                        inserted = true;
                    }
                }
            }
        },
        /**
         * If prop selectedContacts is set, this function transfers the prop-array to this components different arrays.
         * The prop can contain mobile numbers, employee-id or a mix:
         * preselectedContact:{type:string(contactId|employeeId|mobilenumber|teamName|positionName), value:String}
         */
        transferSelectedContacts() {
            if (this.selectedContacts === null || this.selectedContacts === undefined) {
                return;
            }

            for (let pre of this.selectedContacts) {
                this.addContact(pre);
            }
        },
        getContactByPhone(mobile) {
            if (!(mobile instanceof PhoneNumber)) {
                mobile = new PhoneNumber(mobile, 47);
            }

            for (let contact of this.allContacts) {
                const contactMobile = new PhoneNumber(contact.mobile, 47);

                if (mobile.equals(contactMobile)) {
                    return contact;
                } else {
                    if (contact.hasOwnProperty('persons')) {
                        for (let person of contact.persons) {
                            let personMobile = new PhoneNumber(person.mobile, 47);

                            if (mobile.equals(personMobile)) {
                                return person;
                            }
                        }
                    }
                }
            }

            return null;
        },

        removeSelectedContact(contact) {
            const selectedContactIndex = this.smsObj.receivers
                .findIndex(receiver => receiver.personId === contact.personId);

            if (selectedContactIndex >= 0) {
                this.smsObj.receivers.splice(selectedContactIndex, 1);
                this.selectedRecipient.recipients = [];
                this.showRecipientsPopup = false;
            }
        },

        getNumberObject(input) {
            return new PhoneNumber(input, 47);
        },

        addManualContact() {
            if (this.manualContactInput === '') {
                return;
            }
            //Remove spaces
            this.manualContactInput = this.manualContactInput.replace(/\s/g, '');
            let array = this.manualContactInput.split(",");

            if (array.length > 1) {
                for (var receiver of array) {
                    const phone = new PhoneNumber(receiver, 47);
                    const validate = phone.validate();
                    const type = phone.getType();
                    const exists = this.receiverExists(phone);

                    if (validate && type === PhoneNumberType.mobile && !exists) {
                        this.addContact({type: 'mobilenumber', value: receiver});
                    }
                }
                this.manualContactInput = '';
                this.$refs.manualinput.focus();

                return;
            }

            let number = new PhoneNumber(this.manualContactInput, 47);

            if (this.receiverExists(number)) {
                ks.toast("Mottakeren er allerede lagt til");
                this.manualContactInput = '';
                this.$refs.manualinput.focus();

                return;
            }

            let searchResult = this.getContactByPhone(number);

            if (searchResult !== null) {
                this.smsObj.receivers.push(searchResult);
            } else {
                this.addContact({type: 'mobilenumber', value: number.toString()});
            }

            this.manualContactInput = "";
            this.$refs.manualinput.focus();
        },

        getRecipients(group) {
            if (!Array.isArray(group.recipients)) {
                let recipients = this.getRecipientsInGroup(group);
                group.recipients = recipients;

                return group.recipients;
            } else {
                return group.recipients;
            }
        },

        getRecipientsInGroup(group) {
            let recipients = [];

            if (!group.hasOwnProperty('type')) {
                recipients.push(group);

                return recipients;
            }

            if (group.type !== 'position' && group.type !== 'team') {
                recipients.push(group);

                return recipients;
            }

            for (let contact of this.allContacts) {
                if (!contact.hasOwnProperty('persons')) {
                    continue;
                }

                for (let person of contact.persons) {
                    if (! this.hasValidMobile(person)) {
                        continue;
                    }

                    if (group.type === "position" && person.position === group.name) {
                        recipients.push(person);
                    }

                    if (group.type === "team" && person.team === group.name) {
                        recipients.push(person);
                    }
                }
            }

            return recipients;
        },

        hasValidMobile(object) {
            if (!object.hasOwnProperty('mobile')) {
                return false;
            }
            let number = new PhoneNumber(object.mobile, 47);

            return number.validate();
        },

        getPos(element) {
            let el = null;

            if (typeof(element) === 'string') {
                el = this.$refs[element].$el;
            } else {
                el = this.$refs[element.personId][0];
            }

            if (el) {
                return el.getBoundingClientRect();
            }

            return null;
        },

        showRecipients(contact) {
            if (this.showRecipientsPopup && contact.personId === this.selectedRecipient.personId) {
                this.showRecipientsPopup = false;

                return;
            }

            this.selectedRecipient = contact;
            this.selectedRecipient.recipients = this.getRecipients(contact);

            if (this.selectedRecipient.recipients !== []) {
                this.showRecipientsPopup = true;
            }
        },
        backspace() {
            if (this.manualContactInput === '') {
                if (this.smsObj.receivers.length > 0) {
                    this.smsObj.receivers.pop();
                }
            }
        },
        send() {
            if (this.smsObj.message.length === 0) {
                ks.alert(
                    this.getLang("sms-dialog.Tom SMS"),
                    this.getLang("sms-dialog.Du kan ikke sende en tom SMS-melding") + ". " +
                    this.getLang("sms-dialog.Skriv inn litt tekst") + ".",
                );

                return;
            }

            if (this.smsObj.receivers.length === 0) {
                ks.alert(
                    this.getLang("sms-dialog.Ingen mottakere"),
                    this.getLang("sms-dialog.Du har ikke valgt hvem som skal motta SMS-meldingen") + ".",
                );

                return;
            }

            if (this.smsObj.senderName < 0 || this.smsObj.senderName === null || this.smsObj.senderName === undefined) {
                ks.alert(
                    this.getLang("sms-dialog.Ingen avsender"),
                    this.getLang("sms-dialog.Du har ikke valgt avsendernavn") + ".",
                );

                return;
            }

            let sms = {
                message: this.smsObj.link !== ''
                    ? this.smsObj.message + "\n" + this.smsObj.link
                    : this.smsObj.message,
                receivers: this.getRecipientsList(),
                sender: this.smsObj.senderName,
            };

            this.resetData();
            //Closing dialog
            this.modalOpen = false;
            this.$emit('send', sms);
        },

        cancel() {
            this.resetData();
            //Closing dialog
            this.modalOpen = false;
            this.$emit('cancel');
        },

        resetData() {
            this.smsObj.message = "";
            this.smsObj.link = "";
            this.smsObj.receivers = [];
        },

        hasErrors() {
            return this.smsObj.receivers.some(receiver => 'error' in receiver && receiver.error);
        },

        handleReceiverErrors(error) {
            if (error.failed_receivers.length === 0) {
                return;
            }

            let ret = [];

            for (let obj of error.failed_receivers) {
                let added = false;

                for (let i = 0; i < this.smsObj.receivers.length; i++) {
                    let receiver = this.smsObj.receivers[i];

                    if (receiver.mobile === obj) {
                        receiver.error = true;
                        ret.push(receiver);
                        added = true;
                    }
                }

                if (!added) {
                    ret.push({
                        mobile: obj,
                        name: '',
                        error: true,
                    });
                }
            }
            this.smsObj.receivers = ret;

            if (error.message) {
                this.smsObj.message = error.message;
            }

            this.modalOpen = true;
        },
        getRecipientsList() {
            let receivers = [];

            for (let contact of this.uniqueRecipients) {
                let userId = null;
                let contactId = null;
                let contactPersonId = null;

                if (contact.type === 'employee') {
                    userId = contact.employeeId;
                }

                if (contact.type === 'person') {
                    contactPersonId = contact.contact_person_id;
                    contactId = contact.contact_id;
                }

                if (contact.type === 'contact') {
                    contactId = contact.contact_id;
                }

                const receiver = {
                    number: contact.mobile,
                    user_id: userId,
                    contact_id: contactId,
                    contact_person_id: contactPersonId,
                };

                receivers.push(receiver);
            }

            return receivers;
        },

        existsInGroup(contact) {
            const allEmployeesDetected = this.smsObj.receivers.find(obj => {
                return obj.mobile === "+A000";
            });

            if (contact.type !== 'contact' && contact.type !== 'person' && allEmployeesDetected) {
                return true;
            }

            for (let obj of this.smsObj.receivers) {
                if (obj.type === 'position' || obj.type === 'team') {
                    if (!obj.recipients) {
                        obj.recipients = this.getRecipients(obj);
                    }
                    const person = obj.recipients.find(person => person.mobile === contact.mobile);

                    if (person !== undefined) {
                        return true;
                    }
                }
            }

            return false;
        },

        onButtonClick() {
            if (this.redirectToOnClick === "") {
                this.modalOpen = true;

                return;
            }

            window.location = this.redirectToOnClick;
        },
    },
};
