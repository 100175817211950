import kkModal from '../kk-modal/kk-modal.vue';
import NewContact from './components/new-contact.vue';
import ContactsList from './components/contacts-list.vue';
import CustomerVendorSettings from './components/customer-vendor-settings.vue';
import contactsStore from '../../../../vue/views/contacts/stores/contacts-store.js';
import ks from '../../lib/ks';
import { mapActions, mapState } from 'vuex';

import { trans } from '../../mixin/trans';

const defaultClasses = ['mobile'];
const allClasses = [...defaultClasses, 'desktop'];

// @vue/component
export default {
    name: 'kk-contacts',
    components: {
        ContactsList,
        NewContact,
        CustomerVendorSettings,
        kkModal,
    },
    mixins: [trans],
    props: {
        /**
         * type of kk-contacts
         * 'modal' if you want it with the kk-modal
         * 'normal' if you want it as a normal component
         */
        type: {
            type: String,
            default: 'modal',
        },
        modalTitle: {
            type: String,
            default: '',
        },
        addNew: {
            type: Boolean,
            default: false,
        },
        /**
         * example: returned from GET /api/addressbook
         */
        contacts: {
            type: Array,
            default() {
                return [];
            },
        },

        /**
         * example: returned from GET /api/addressbook
         * Will be displayed before the user selects anything, or
         * focuses the search bar.
         */
        lastUsed: {
            type: Array,
            default() {
                return [];
            },
        },

        /**
         * Max number of selected contacts. Minimum 1.
         */
        max: {
            type: Number,
            default: 1,
            validator: (max) => {
                return max >= 1;
            },
        },

        /**
         * v-model
         * example: [ {contactId: 2, personId: 3}, {contactId: 2, personId: 4},
         *                      {contactId: 2, personId: 5} ]
         */
        value: {
            type: Array,
            default: () => [],
        },

        /**
         * Component css classes applied to the root element
         */
        classes: {
            type: Array,
            default: () => defaultClasses,
            validator: classes => classes.every(claass => allClasses.includes(claass)),
        },

        /**
         * Primary contact info
         * Set to either email, mobile or none.
         * If the contact does not have the necessary contact info, it will not be selectable.
         */
        primary: {
            type: String,
            default: 'email',
            validator: primary => ['email', 'mobile', 'none', 'either'].includes(primary),
        },
        categories: {
            type: Array,
            default: () => [],
        },
        showContactModal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            langGetOthers: Lang.get('shared.Andre'),
            addNewMode: false,
            settingsError: false,
            settingsOpen: false,
            disableButton: false,
        };
    },

    computed: {
        ...mapState({
            settings: state => state.contactsStore.settings,
            countries: state => state.contactsStore.contactData.countries,
        }),
        norwayCountry() {
            return this.countries.find(country => country.code === 'NO');
        },
        mobile() {
            return this.classes.includes('mobile');
        },
        contactOptions() {
            const _getCategories = (categories, category) => (!categories)
                ? (!category) ? [this.langGetOthers] : [category]
                : (Array.isArray(categories))
                    ? categories.map(c => c.name)
                    : [categories];

            return this.contacts.map((contact) => {
                return {
                    ...contact,
                    personId: null,
                    contactId: contact.id,
                    categories: _getCategories(contact.categories, contact.category),
                    persons: contact.persons.map(person => ({
                        ...person,
                        name: person.name || person.full_name,
                        personId: person.id,
                        contactId: contact.id,
                        categories: _getCategories(contact.categories, contact.category),
                    })).sort((a, b) => (a.name || '').toUpperCase().localeCompare((b.name || '').toUpperCase())),
                };
            }).sort((a, b) => a.name.toUpperCase().localeCompare(b.name.toUpperCase()));
        },
    },
    beforeCreate() {
        if (!this.$store.hasModule('contactsStore')) {
            this.$store.registerModule('contactsStore', contactsStore);
        }
    },
    created() {
        this.fetchSettings();
        this.fetchData();
    },

    methods: {
        ...mapActions('contactsStore', [
            'fetchSettings',
            'fetchData',
        ]),
        last(array) {
            return array[array.length - 1];
        },
        async createContact({ contact, contactPerson }) {
            this.disableButton = true;
            let payload = contact;
            payload.persons ||= [];
            payload.categories ||= [];

            if (contactPerson) {
                contactPerson.full_name = contactPerson.first_name + ' ' + contactPerson.last_name;
                contactPerson.id = payload.persons ? -(payload.persons.length + 1) : 0;
                payload.persons.push(contactPerson);
            }

            // remove duplicates
            const tempMap = new Map();
            payload.persons.forEach(p => tempMap.set(p.id, p));
            payload.persons = [...tempMap.values()];

            if (this.settings['contact.first_customer_number'] === null) {
                this.activateSpinner(false);
                this.settingsError = true;
                this.settingsOpen = true;
                this.disableButton = false;

                return this.settingsOpen;
            }

            try {
                // Post contact if new
                if (!payload.id) {
                    payload.addresses ||= [];

                    if (payload.postal_code && payload.city) {
                        payload.addresses.push(
                            {
                                id: -1,
                                label: payload.address_line_1 || payload.city,
                                address_line_1: payload.address_line_1 || null,
                                postal_code: payload.postal_code,
                                city: payload.city,
                                country: {
                                    ...this.norwayCountry,
                                },
                            },
                        );
                    }

                    payload.default_delivery_address_id = -1;
                    payload.default_invoice_address_id = -1;

                    if (!Array.isArray(payload.categories)) {
                        payload.categories = [payload.categories];
                    }

                    await ks.post('/api/contacts?include=persons', payload)
                        .then((response) => {
                            this.setContact(response);
                        }).catch((error) => {
                            console.error(error);
                            this.disableButton = false;
                            ks.alert(
                                this.trans('project-creation.Feil inndata'),
                                `
                                ${this.trans('project-creation.Vi ser du har sendt noe vi ikke forventet')}. <br><br>
                                ${this.trans('project-creation.Vennligst se gjennom skjema og rett opp i feil')}. <br><br>
                                `
                                + Object.values(error.data.errors).flatMap(a => a).join('<br>\n'),
                            );
                        });
                }

                // Put contact if existent
                if (payload.id) {
                    payload.default_delivery_address_id = payload.default_delivery_address?.id ?? null;
                    payload.default_invoice_address_id = payload.default_invoice_address?.id ?? null;

                    payload.categories = payload.categories.map((cat) => {
                        if (typeof cat === 'string') {
                            return { name: cat };
                        }

                        return cat;
                    });

                    await ks.put(`/api/contacts/${payload.id}?include=persons`, payload)
                        .then((response) => {
                            this.setContact(response);
                        })
                        .catch((error) => {
                            console.error(error);
                            this.disableButton = false;
                            ks.alert(
                                this.trans('project-creation.Feil inndata'),
                                `${this.trans('project-creation.Vi ser du har sendt noe vi ikke forventet')}. <br><br> ${this.trans('project-creation.Vennligst se gjennom skjema og rett opp i feil')}`,
                            );
                        });
                }
            } catch (error) {
                this.disableButton = false;
                console.error(error);
                ks.alert(
                    this.trans('project-creation.Feil inndata'),
                    `${this.trans('project-creation.Vi ser du har sendt noe vi ikke forventet')}. <br><br> ${this.trans('project-creation.Vennligst se gjennom skjema og rett opp i feil')}`,
                );
            }
        },
        openNewMode(e) {
            this.addNewMode = e;
        },
        changeSettingsStatus(e) {
            this.settingsOpen = e;
        },
        closeModal() {
            this.openNewMode(false);
            this.$emit('show-modal', false);
        },
        setContactList(e) {
            this.$emit('input', e);
        },
        setContact(response) {
            this.disableButton = false;
            const newPerson = this.last(response.data.persons);
            this.$emit('set-contacts', {
                newContact: response.data,
                newPerson,
            });
            this.openNewMode(false);
        },
        activateSpinner(e) {
            this.$emit('set-spinner', e);
        },
    },
};
