import Vue from 'vue';

/**
 * Font Awesome
 * Import and library.add all icons we use in desktop and mobile here for now.
 * If we ever switch to using vue-router and split up our bundled files,
 * we should consider moving the imports out to different view components.
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';

// Solid icons
import { faPiggyBank as faPiggyBankSolid } from '@fortawesome/pro-solid-svg-icons/faPiggyBank';
import { faClock as faClockSolid } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faHistory as faHistorySolid } from '@fortawesome/pro-solid-svg-icons/faHistory';
import { faPlusCircle as faPlusCircleSolid } from '@fortawesome/pro-solid-svg-icons/faPlusCircle';
import { faLock as faLockSolid } from '@fortawesome/pro-solid-svg-icons/faLock';
import { faEnvelope as faEnvelopeSolid } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faKey as faKeySolid } from '@fortawesome/pro-solid-svg-icons/faKey';
import { faCheckCircle as faCheckCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faUserHardHat as faUserHardHatSolid } from '@fortawesome/pro-solid-svg-icons/faUserHardHat';
import { faMobileAndroidAlt as faMobileAndroidAltSolid } from '@fortawesome/pro-solid-svg-icons/faMobileAndroidAlt';
import { faFilePdf as faFilePdfSolid } from '@fortawesome/pro-solid-svg-icons/faFilePdf';
import { faFileExcel as faFileExcelSolid } from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import { faFileWord as faFileWordSolid } from '@fortawesome/pro-solid-svg-icons/faFileWord';
import { faFileArchive as faFileArchiveSolid } from '@fortawesome/pro-solid-svg-icons/faFileArchive';
import { faFile as faFileSolid } from '@fortawesome/pro-solid-svg-icons/faFile';
import { faFileLines as faFileLinesSolid } from '@fortawesome/pro-solid-svg-icons/faFileLines';
import { faImage as faImageSolid } from '@fortawesome/pro-solid-svg-icons/faImage';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons/faExclamation';
import { faCheck as faCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faTimes as faTimesSolid } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faCog as faCogSolid } from '@fortawesome/pro-solid-svg-icons/faCog';
import { faShare as faShareSolid } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faCircleExclamation as faCircleExclamationSolid } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { faCircleCheck as faCircleCheckSolid } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faCircleXmark as faCircleXmarkSolid } from '@fortawesome/pro-solid-svg-icons/faCircleXmark';
import { faCircleInfo as faCircleInfoSolid } from '@fortawesome/pro-solid-svg-icons/faCircleInfo';
import { faTriangleExclamation as faTriangleExclamationSolid } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation';
import { faUserChef as faUserChefSolid } from '@fortawesome/pro-solid-svg-icons/faUserChef';

// Regular icons
import { faBan } from '@fortawesome/pro-regular-svg-icons/faBan';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faEnvelope as farEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faRedo } from '@fortawesome/pro-regular-svg-icons/faRedo';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faLock as faLockRegular } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faPause } from '@fortawesome/pro-regular-svg-icons/faPause';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faUsers } from '@fortawesome/pro-regular-svg-icons/faUsers';
import { faCalendarAlt as faCalendarAltRegular } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faExternalLink as faExternalLinkRegular } from '@fortawesome/pro-regular-svg-icons/faExternalLink';
import { faCircleDollar } from '@fortawesome/pro-regular-svg-icons/faCircleDollar';
import { faChevronLeft as farChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faExclamation as farFaExclamation } from '@fortawesome/pro-regular-svg-icons/faExclamation';
import { faCircle as farFaCircle } from '@fortawesome/pro-regular-svg-icons';
import { faLandmark as farFaLandmark } from '@fortawesome/pro-regular-svg-icons';
import { faLocationCrosshairs as farFaLocationCrosshairs } from '@fortawesome/pro-regular-svg-icons';

// Light icons
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck';
import { faAddressBook } from '@fortawesome/pro-light-svg-icons/faAddressBook';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';
import { faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons/faArrowCircleLeft';
import { faArrowCircleRight } from '@fortawesome/pro-light-svg-icons/faArrowCircleRight';
import { faCalculator } from '@fortawesome/pro-light-svg-icons/faCalculator';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons/faExternalLink';
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faExpandWide } from '@fortawesome/pro-light-svg-icons/faExpandWide';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faFileCheck } from '@fortawesome/pro-light-svg-icons/faFileCheck';
import { faFileDownload } from '@fortawesome/pro-light-svg-icons/faFileDownload';
import { faImages } from '@fortawesome/pro-light-svg-icons/faImages';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons/faLockAlt';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faPhoneSlash } from '@fortawesome/pro-light-svg-icons/faPhoneSlash';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons/faPlusCircle';
import { faPrint } from '@fortawesome/pro-light-svg-icons/faPrint';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons/faMinusCircle';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons/faShieldCheck';
import { faSync } from '@fortawesome/pro-light-svg-icons/faSync';
import { faTags } from '@fortawesome/pro-light-svg-icons/faTags';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faHourglassHalf } from '@fortawesome/pro-light-svg-icons/faHourglassHalf';
import { faMapPin } from '@fortawesome/pro-light-svg-icons/faMapPin';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faFileSignature } from '@fortawesome/pro-light-svg-icons/faFileSignature';
import { faSort } from '@fortawesome/pro-light-svg-icons/faSort';
import { faSortUp } from '@fortawesome/pro-light-svg-icons/faSortUp';
import { faSortDown } from '@fortawesome/pro-light-svg-icons/faSortDown';
import { faIdCardAlt } from '@fortawesome/pro-light-svg-icons/faIdCardAlt';
import { faIdCard } from '@fortawesome/pro-light-svg-icons/faIdCard';
import { faMobile } from '@fortawesome/pro-light-svg-icons/faMobile';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons/faCalendarAlt';
import { faCalendarEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faBuilding } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faProjectDiagram } from '@fortawesome/pro-light-svg-icons/faProjectDiagram';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { faFileChartLine } from '@fortawesome/pro-light-svg-icons/faFileChartLine';
import { faFileExcel } from '@fortawesome/pro-light-svg-icons/faFileExcel';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faCheckDouble } from '@fortawesome/pro-light-svg-icons/faCheckDouble';
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy';
import { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns';
import { faUserHardHat } from '@fortawesome/pro-light-svg-icons/faUserHardHat';
import { faInboxIn } from '@fortawesome/pro-light-svg-icons/faInboxIn';
import { faInboxOut } from '@fortawesome/pro-light-svg-icons/faInboxOut';
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faCog as faCogLight } from '@fortawesome/pro-light-svg-icons/faCog';
import { faCloudUploadAlt } from '@fortawesome/pro-light-svg-icons/faCloudUploadAlt';
import { faArrowToTop } from '@fortawesome/pro-light-svg-icons/faArrowToTop';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faUnlock } from '@fortawesome/pro-light-svg-icons/faUnlock';
import { faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpen';
import { faUsersCrown } from '@fortawesome/pro-light-svg-icons/faUsersCrown';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faSignIn } from '@fortawesome/pro-light-svg-icons/faSignIn';
import { faUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash';
import { faShoppingCart } from '@fortawesome/pro-light-svg-icons/faShoppingCart';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faInbox } from '@fortawesome/pro-light-svg-icons/faInbox';
import { faFileInvoice } from '@fortawesome/pro-light-svg-icons/faFileInvoice';
import { faLockOpen as faLockOpenLight } from '@fortawesome/pro-light-svg-icons/faLockOpen';
import { faThLarge } from '@fortawesome/pro-light-svg-icons/faThLarge';
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons/faMoneyBill';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons/faEllipsisH';
import { faHashtag } from '@fortawesome/pro-light-svg-icons/faHashtag';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faAt } from '@fortawesome/pro-light-svg-icons/faAt';
import { faSlash } from '@fortawesome/pro-light-svg-icons/faSlash';
import { faGear as faGearLight } from '@fortawesome/pro-light-svg-icons/faGear';
import { faDroplet as faDropletLight } from '@fortawesome/pro-light-svg-icons/faDroplet';
import { faTemperatureFull as faTemperatureFullLight } from '@fortawesome/pro-light-svg-icons/faTemperatureFull';
import { faWind as faWindLight } from '@fortawesome/pro-light-svg-icons/faWind';
import { faArrowDown as faArrowDownLight } from '@fortawesome/pro-light-svg-icons/faArrowDown';
import { faArrowUp as faArrowUpLight } from '@fortawesome/pro-light-svg-icons/faArrowUp';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons/faClipboardListCheck';
import { faShareNodes } from '@fortawesome/pro-light-svg-icons/faShareNodes';
import { faArrowRightArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowRightArrowLeft';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faCommentQuestion } from '@fortawesome/pro-light-svg-icons/faCommentQuestion';
import { faCommentLines } from '@fortawesome/pro-light-svg-icons/faCommentLines';
import { faCommentSmile } from '@fortawesome/pro-light-svg-icons/faCommentSmile';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faCalendarClock } from '@fortawesome/pro-light-svg-icons/faCalendarClock';
import { faUserTie } from '@fortawesome/pro-light-svg-icons/faUserTie';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faUnicorn } from '@fortawesome/pro-light-svg-icons/faUnicorn';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faRotate } from '@fortawesome/pro-light-svg-icons/faRotate';
import { faWandMagicSparkles as faWandMagicSparklesLight } from '@fortawesome/pro-light-svg-icons/faWandMagicSparkles';
import { faBallotCheck } from '@fortawesome/pro-light-svg-icons/faBallotCheck';
import { faPenToSquare } from '@fortawesome/pro-light-svg-icons/faPenToSquare';
import { faClapperboardPlay } from '@fortawesome/pro-light-svg-icons/faClapperboardPlay';
import { faCirclePlus } from '@fortawesome/pro-light-svg-icons/faCirclePlus';
import { faCircleCheck as faCircleCheckLight } from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import { faHouseBuilding as faHouseBuildingLight } from '@fortawesome/pro-light-svg-icons/faHouseBuilding';
import { faHouse as faHouseLight } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faMailbox as faMailboxLight } from '@fortawesome/pro-light-svg-icons/faMailbox';

library.add(
    faUserCheck,
    faAddressBook,
    faAngleUp,
    faArrowCircleRight,
    faBan,
    faBell,
    faCalculator,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faClock,
    faComment,
    faCopy,
    faEdit,
    faEllipsisV,
    faExternalLink,
    faEnvelope,
    faExclamationTriangle,
    faExpandWide,
    faFile,
    faFileDownload,
    faFileCheck,
    faImages,
    faLock,
    faLockAlt,
    faPhone,
    faPhoneSlash,
    faPlus,
    faPlusCircle,
    faPrint,
    faMinusCircle,
    faRedo,
    faSearch,
    faShieldCheck,
    faSpinnerThird,
    faSync,
    faTags,
    faThumbsUp,
    faTimes,
    faTimesCircle,
    faTrashAlt,
    farEnvelope,
    faPiggyBank,
    faPiggyBankSolid,
    faHourglassHalf,
    faMapPin,
    faArrowRight,
    faFileSignature,
    faSort,
    faSortUp,
    faSortDown,
    faIdCardAlt,
    faMobile,
    faIdCard,
    faFilter,
    faCalendarAlt,
    faCalendarEdit,
    faBuilding,
    faProjectDiagram,
    faListUl,
    faFileExport,
    faClockSolid,
    faPlusCircleSolid,
    faHistorySolid,
    faSlidersH,
    faFileChartLine,
    faFileExcel,
    faArrowToBottom,
    faCheckDouble,
    faCaretDown,
    faColumns,
    faUserHardHat,
    faInboxIn,
    faInboxOut,
    faClone,
    faInfoCircle,
    faCogLight,
    faCogSolid,
    faCloudUploadAlt,
    faArrowToTop,
    faFileAlt,
    faLockRegular,
    faPause,
    faCheck,
    faLockOpen,
    faLockOpenLight,
    faUnlock,
    faLockSolid,
    faEnvelopeSolid,
    faKeySolid,
    faEnvelopeOpen,
    faUsersCrown,
    faUsers,
    faCalendarAltRegular,
    faKey,
    faSignIn,
    faCheckCircleSolid,
    faUsersCrown,
    faUserSlash,
    faKey,
    faUserHardHatSolid,
    faCheckCircleSolid,
    faEye,
    faEyeSlash,
    faRotate,
    faMobileAndroidAltSolid,
    faArrowCircleLeft,
    faShoppingCart,
    faPaperclip,
    faFilePdfSolid,
    faFileExcelSolid,
    faFileWordSolid,
    faFileArchiveSolid,
    faFileSolid,
    faImageSolid,
    faFileLinesSolid,
    faInbox,
    faFileInvoice,
    faExternalLinkRegular,
    faThLarge,
    faMoneyBill,
    faEllipsisH,
    faCircleDollar,
    faHashtag,
    faUser,
    faAt,
    faSlash,
    faExclamation,
    faCheckSolid,
    faTimesSolid,
    faCircleSolid,
    faGearLight,
    faWindLight,
    faTemperatureFullLight,
    faDropletLight,
    faArrowDownLight,
    faArrowUpLight,
    faChartLine,
    faClipboardListCheck,
    faCircleInfo,
    faArrowRightArrowLeft,
    faShareNodes,
    faShareSolid,
    faCircleExclamationSolid,
    faCircleCheckSolid,
    faCircleXmarkSolid,
    faCommentQuestion,
    faCommentLines,
    faCommentSmile,
    faMinus,
    faPen,
    faCalendarClock,
    faUserTie,
    faGlobe,
    farChevronLeft,
    faUnicorn,
    faStar,
    farFaExclamation,
    faWandMagicSparklesLight,
    farFaCircle,
    faBallotCheck,
    faPenToSquare,
    faTriangleExclamationSolid,
    faClapperboardPlay,
    faCircleInfoSolid,
    faCirclePlus,
    faCircleCheckLight,
    farFaLandmark,
    farFaLocationCrosshairs,
    faUserChefSolid,
    faHouseBuildingLight,
    faHouseLight,
    faMailboxLight,
);

// Name the component <fa-icon> instead of <font-awesome-icon>, which is a bit long
Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-stack', FontAwesomeLayers);
Vue.component('fa-text', FontAwesomeLayersText);
