export const textarea = {
    methods: {
        autoExpandTextarea(event) {
            setTimeout(function() {
                event.target.style.cssText = 'height:auto; padding:0';
                event.target.style.cssText = 'height:' + event.target.scrollHeight + 'px';
            }, 0);
        },

        /**
         * returns true if the given comment-field should receive focus after being expanded, false otherwise
         * @param element - the parent element which holds the display: none-styling that hides/shows the textarea
         * @param content - the comment content
         * @returns {boolean}
         */
        shouldReceiveFocus: function(element, content) {
            let contentLength = 0;
            let isHidden = false;

            if (content !== null && content !== undefined) {
                contentLength = content.length;
            }

            if (element.getAttribute("style") !== null || element.getAttribute("style") !== "") {
                isHidden = true;
            }

            return isHidden && contentLength === 0;
        },

        /**
         * moves the input-focus to the textareaRef if there is no content yet
         * @param elementId
         * @param textareaRef
         * @param content
         */
        focusTextarea: function(elementId, textareaRef, content) {
            let element = document.getElementById(elementId);
            let textarea = this.$refs[textareaRef];

            if (this.shouldReceiveFocus(element, content)) {
                setTimeout( () => {
                    textarea.focus();
                }, 400);
            }
        },
    },
};
