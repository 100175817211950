<template>
    <div ref="kk-collapse" :class="['kk-collapse', { disabled, open: !collapsed }]" @click="openIfCollapsed">
        <header v-if="$slots.header || $scopedSlots.header" class="header-slot" @click.stop="toggleCollapse">
            <slot name="header" :show="show" />
        </header>
        <header v-else class="header" @click.stop="toggleCollapse">
            <div v-if="$slots.icon" class="icon">
                <slot name="icon" />
            </div>
            <h3 class="title">
                <slot name="title" />
            </h3>
            <svg-icon
                v-if="loading"
                key="loading"
                class="chevron"
                name="loading"
                height="20"
                width="20"
            />
            <svg-icon v-else key="chevron" :class="['chevron', {open: show, close: !show}]" name="chevron" />
        </header>

        <div v-if="$slots['sub-header'] || $scopedSlots['sub-header']" class="sub-header">
            <slot name="sub-header" :show="show" />
        </div>

        <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
            <template v-if="alwaysCreateContent">
                <section v-show="show" class="collapse-content">
                    <slot name="content" />
                </section>
            </template>
            <template v-else>
                <section v-if="show" class="collapse-content">
                    <slot name="content" />
                </section>
            </template>
        </transition>
    </div>
</template>

<script>
import svgIcon from "../svg-icon/svg-icon.vue";

export default {
    name: 'kk-collapse',
    components: {
        svgIcon,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        collapsed: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // If true, the content will always be created, even if the collapse is closed.
        alwaysCreateContent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
        };
    },
    watch: {
        collapsed: {
            immediate: true,
            handler(boolean) {
                this.show = !boolean;
            },
        },
    },
    methods: {
        resetCollapse() {
            this.show = false;
        },
        openIfCollapsed() {
            if (this.show === false) {
                this.toggleCollapse();
            }
        },
        toggleCollapse() {
            this.$emit('clicked');

            if (this.disabled) {
                return;
            }

            this.show = !this.show;
            this.$emit('collapsed', !this.show);
        },
        /**
         * Fancy height animations by:
         * @see https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
         */
        afterEnter(element) {
            element.style.height = 'auto';
            this.$emit('animation-done');
        },
        leave(element) {
            const height = getComputedStyle(element).height;

            element.style.height = height;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            setTimeout(() => {
                element.style.height = 0;
            });
        },
        enter(element) {
            const width = getComputedStyle(element).width;

            element.style.width = width;
            element.style.position = 'absolute';
            element.style.visibility = 'hidden';
            element.style.height = 'auto';

            const height = getComputedStyle(element).height;

            element.style.width = null;
            element.style.position = null;
            element.style.visibility = null;
            element.style.height = 0;

            // Force repaint to make sure the
            // animation is triggered correctly.
            getComputedStyle(element).height;

            // Trigger the animation.
            // We use `setTimeout` because we need
            // to make sure the browser has finished
            // painting after setting the `height`
            // to `0` in the line above.
            setTimeout(() => {
                element.style.height = height;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.kk-collapse {
    min-height: 80px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 25px 20px;
    .header {
        display: flex;
        align-items: center;
        cursor: pointer;
        .title {
            font-size: 1.4em;
            font-weight: 400;
            margin: 0;
        }
        .icon {
            margin-right: 20px;
            display: flex; /* Used to remove the whitespace */
        }
        .chevron {
            margin-left: auto;
            margin-right: 5px;
            transition: transform 350ms ease-in-out;
            &.open {
                transform: rotate(-180deg);
            }
        }
    }
    .collapse-content {
        /* Tell browser that we will animate height to optimize animation */
        will-change: height;
        -webkit-font-smoothing: antialiased;
    }

    .expand-enter-active,
    .expand-leave-active {
        transition: height 350ms ease;
        overflow: hidden;
    }

    .expand-enter,
    .expand-leave-to {
        height: 0;
    }
}
</style>
