'use strict';

/**
 * Represents a phone-number
 */
export class PhoneNumber {
    /**
     * Initiates a PhoneNumber instance from a string
     * @param { string } number
     * @param { number } defaultCountry
     */
    constructor(number, defaultCountry) {
        if (number !== null && number !== undefined) {
            number = number.toString();
        } else {
            number = "";
        }

        if (defaultCountry) {
            if (typeof(defaultCountry) === 'object') {
                this.defaultCountry = defaultCountry.code;
            } else {
                this.defaultCountry = defaultCountry;
            }
        }

        //Remove spaces
        number = number.replace(/\s/g, '');

        //Checking for country code
        let plus = number.charAt(0) === '+';
        let countryCode = null;

        if (plus) {
            let activeCountry = undefined;

            for (let country of Object.values(PhoneNumberCountry)) {
                const code = country.code;

                if (!code) {
                    continue;
                }
                const length = code.toString().length;
                const slice = number.slice(1, 1 + length);

                if (code.toString() === slice.toString()) {
                    activeCountry = country;
                }
            }

            if (activeCountry) {
                countryCode = activeCountry.code;
            } else {
                countryCode = number.slice(1, 3);
            }
            number = number.slice(1 + countryCode.toString().length, number.length);
        }

        let zeroPlus = number.slice(0, 2) === '00';

        if (zeroPlus) {
            let activeCountry = undefined;

            for (let country of Object.values(PhoneNumberCountry)) {
                const code = country.code;

                if (!code) {
                    continue;
                }
                const length = code.toString().length;
                const slice = number.slice(2, 2 + length);

                if (code.toString() === slice.toString()) {
                    activeCountry = country;
                }
            }

            if (activeCountry) {
                countryCode = activeCountry.code;
            } else {
                countryCode = number.slice(2, 4);
            }

            number = number.slice(2 + countryCode.toString().length, number.length);
        }

        if (countryCode === null && this.defaultCountry) {
            countryCode = this.defaultCountry.code;
        }

        //Number must only be numeric
        if (number.match(/^-{0,1}\d+$/)) {
            this.phoneNumber = number;
        } else {
            this.phoneNumber = "";
        }

        this.countryCode = countryCode;
    }

    /**
     * Compares a PhoneNumber object to another PhoneNumberObject.
     * @param {PhoneNumber} phoneNumber
     * @returns {Boolean}
     */
    equals(phoneNumber) {
        if (!(phoneNumber instanceof PhoneNumber) && typeof(phoneNumber) !== 'string') {
            return new Error('Trying to compare a phoneNumber object to a none phoneNumber-object');
        }

        if (typeof(phoneNumber) === 'string') {
            phoneNumber = new PhoneNumber(phoneNumber);
        }

        if (phoneNumber.phoneNumber.toString() !== this.phoneNumber.toString()) {
            return false;
        }
        const thisCountry = this.getCountry();
        const otherCountry = phoneNumber.getCountry();

        if (thisCountry.code !== otherCountry.code) {
            return false;
        }

        return true;
    }

    /**
     * Returns a country object based on the country code specified in the phone number,
     * or unspecifiedCountry if no country code is specified
     */
    getCountry() {
        if (!this.countryCode) {
            if (this.defaultCountry) {
                this.countryCode = this.defaultCountry;
            } else {
                return PhoneNumber.unspecifiedCountry;
            }
        }
        let currentCountry = undefined;

        for (let country of Object.values(PhoneNumberCountry)) {
            if (!country.code) {
                continue;
            }

            if (country.code.toString() === this.countryCode.toString()) {
                currentCountry = country;
            }
        }

        if (currentCountry) {
            return currentCountry;
        }

        return PhoneNumberCountry.Unspecified;
    }

    /**
     * Get the type of number based on the country-code rules, or undefined if no country is set
     */
    getType() {
        let country = this.getCountry();

        if (!country) {
            return undefined;
        }

        const mobileDigits = country.rules.mobile.digitsIdentifiers;
        let findMatchingMobileDigit = mobileDigits.find(digit => {
            let digitLength = digit.toString().length;
            let compareTo = this.phoneNumber.slice(0, digitLength).toString();
            let compare = digit.toString();

            return compareTo === compare;
        });

        if (findMatchingMobileDigit !== undefined) {
            const min = country.rules.mobile.minLength;
            const max = country.rules.mobile.maxLength;
            const length = this.phoneNumber.toString().length;

            if (length >= min && length <= max) {
                return PhoneNumberType.mobile;
            }
        }

        const regularDigits = country.rules.regular.digitsIdentifiers;
        let findMatchingRegularDigit = regularDigits.find(digit => {
            let digitLength = digit.toString().length;
            let compareTo = this.phoneNumber.slice(0, digitLength).toString();
            let compare = digit.toString();

            return compareTo === compare;
        });

        if (findMatchingRegularDigit !== undefined) {
            const min = country.rules.regular.minLength;
            const max = country.rules.regular.maxLength;
            const length = this.phoneNumber.toString().length;

            if (length >= min && length <= max) {
                return PhoneNumberType.regular;
            }
        }

        if (mobileDigits.length === 0) {
            return PhoneNumberType.mobile;
        }

        if (regularDigits.length === 0) {
            return PhoneNumberType.regular;
        }

        return undefined;
    }

    /**
     * Validates a phone number according to country specific rules
     * @returns {Boolean} (true = valid, false = invalid)
     */
    validate() {
        const country = this.getCountry();
        const type = this.getType();

        if (type === PhoneNumberType.mobile) {
            const min = country.rules.mobile.minLength;
            const max = country.rules.mobile.maxLength;

            if (min === 0 || max === 0) {
                return true;
            }

            const length = this.phoneNumber.toString().length;

            if (length >= min && length <= max) {
                return true;
            }

            return false;
        }

        if (type === PhoneNumberType.regular) {
            const min = country.rules.regular.minLength;
            const max = country.rules.regular.maxLength;

            if (min === 0 || max === 0) {
                return true;
            }

            const length = this.phoneNumber.toString().length;

            if (length >= min && length <= max) {
                return true;
            }

            return false;
        }
    }

    /**
     * Returns a string representing the phonenumber object.
     * Includes countryCode if parameter is set to true
     * @param {boolean} [includeCountryCode=true] - Wheter or not to include the country code (default = true)
     * @param {boolean} [applyFormat = true] - Whether to apply a string format or not (default = true)
     * @returns {string}
     */
    toString(includeCountryCode = true, applyFormat = true) {
        if (!this.countryCode && !this.phoneNumber) {
            return "Invalid phonenumber";
        }

        if (includeCountryCode) {
            if (this.countryCode && this.phoneNumber) {
                return '+' + this.countryCode + ' ' + (applyFormat ? this.formatNumber() : this.phoneNumber);
            }

            if (!this.countryCode && this.phoneNumber) {
                if (this.defaultCountry) {
                    return '+' + this.defaultCountry + ' ' + (applyFormat ? this.formatNumber() : this.phoneNumber);
                }

                return (applyFormat ? this.formatNumber() : this.phoneNumber);
            }
        } else {
            return (applyFormat ? this.formatNumber() : this.phoneNumber);
        }
    }

    /**
     * Returns a formatted string of the phonenumber based on rules in the current country
     * @returns {string}
     */
    formatNumber() {
        const country = this.getCountry();

        if (!country) {
            return this.phoneNumber;
        }

        const type = this.getType();

        if (!type) {
            return this.phoneNumber;
        }

        let format = '';

        if (type === PhoneNumberType.mobile) {
            format = country.formats.mobile;
        } else {
            format = country.formats.regular;
        }

        if (format === "") {
            return this.phoneNumber;
        }

        const formatLength = format.replace(/\s/g, '').length;
        const numberLength = this.phoneNumber.toString().length;

        if (formatLength !== numberLength) {
            return this.phoneNumber;
        }

        let formatArray = format.split(' ');
        let result = "";
        let count = 0;

        formatArray.forEach(block => {
            const length = block.toString().length;

            if (count > 0) {
                result = result + " ";
            }

            result = result + this.phoneNumber.slice(count, count + length);
            count += length;
        });

        return result;
    }
}

export const PhoneNumberType = {
    mobile: 1,
    regular: 2,
};

export const PhoneNumberCountry = {
    //THE UNSPECIFIED COUNTRY MUST ALWAYS BE AT INDEX 0
    Unspecified: {
        country: 'Unspecified',
        code: null,
        rules: {
            mobile: {
                digitsIdentifiers: [],
                minLength: 5,
                maxLength: 15,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 5,
                maxLength: 15,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Norway: {
        country: 'Norway',
        code: 47,
        rules: {
            mobile: {
                digitsIdentifiers: [4, 9],
                minLength: 8,
                maxLength: 8,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 8,
                maxLength: 8,
            },
        },
        formats: {
            mobile: 'xxx xx xxx',
            regular: 'xx xx xx xx',
        },
    },
    Sweeden: {
        country: 'Sweeden',
        code: 46,
        rules: {
            mobile: {
                digitsIdentifiers: [70, 72, 73, 76, 79],
                minLength: 9,
                maxLength: 9,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 7,
                maxLength: 9,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Denmark: {
        country: 'Denmark',
        code: 45,
        rules: {
            mobile: {
                digitsIdentifiers: [2, 30, 31, 40, 41, 42, 50, 51, 52, 53, 60, 61, 71, 81, 91, 92, 93],
                minLength: 8,
                maxLength: 8,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 8,
                maxLength: 8,
            },
        },
        formats: {
            mobile: 'xx xx xx xx',
            regular: 'xx xx xx xx',
        },
    },
    Poland: {
        country: 'Poland',
        code: 48,
        rules: {
            mobile: {
                digitsIdentifiers: [45, 50, 51, 53, 57, 60, 66, 69, 72, 73, 78, 79, 88],
                minLength: 9,
                maxLength: 9,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 9,
                maxLength: 9,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Belgium: {
        country: 'Belgium',
        code: 32,
        rules: {
            mobile: {
                digitsIdentifiers: [455, 456, 460, 465, 466, 467, 468, 47, 48, 49],
                minLength: 9,
                maxLength: 10,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 9,
                maxLength: 10,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Estonia: {
        country: 'Estonia',
        code: 372,
        rules: {
            mobile: {
                digitsIdentifiers: [5, 81, 82, 83, 84],
                minLength: 7,
                maxLength: 8,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 7,
                maxLength: 8,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Latvia: {
        country: 'Latvia',
        code: 371,
        rules: {
            mobile: {
                digitsIdentifiers: [2],
                minLength: 8,
                maxLength: 8,
            },
            regular: {
                digitsIdentifiers: [6],
                minLength: 8,
                maxLength: 8,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Lithuania: {
        country: 'Lithuania',
        code: 370,
        rules: {
            mobile: {
                digitsIdentifiers: [6],
                minLength: 8,
                maxLength: 8,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 8,
                maxLength: 8,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    UK: {
        country: 'United Kingdom',
        code: 44,
        rules: {
            mobile: {
                digitsIdentifiers: [7],
                minLength: 10,
                maxLength: 10,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 7,
                maxLength: 10,
            },
        },
        formats: {
            mobile: 'xx xxxx xxxx',
            regular: '',
        },
    },
    Finland: {
        country: 'Finland',
        code: 358,
        rules: {
            mobile: {
                digitsIdentifiers: [4, 50],
                minLength: 6,
                maxLength: 11,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 5,
                maxLength: 12,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
    Romania: {
        country: 'Romania',
        code: 40,
        rules: {
            mobile: {
                digitsIdentifiers: [7],
                minLength: 9,
                maxLength: 10,
            },
            regular: {
                digitsIdentifiers: [],
                minLength: 9,
                maxLength: 10,
            },
        },
        formats: {
            mobile: '',
            regular: '',
        },
    },
};
