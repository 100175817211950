/** Mocking object for the Lang.get() api */
const get = key => {
    if (key.includes('.')) {
        return key.split('.')[1];
    } else {
        return key;
    }
};

const choice = (key, qty) => {
    let choices = [];

    for (let i = 0; i < qty; ++i) {
        choices.push(`${key}_${i}`);
    }

    return choices;
};

export const LangMocker = {
    get,
    choice,
};
