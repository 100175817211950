<template>
    <div
        v-if="!category[childrenKeyName] || category[childrenKeyName].length === 0"
        :class="['category', {checked: isSelected}]"
        :style="{marginLeft: (indention > 0 ? 10 : 0) + 'px'}"
    >
        <div class="title">
            {{ category[title] }}
        </div>
        <kk-checkbox
            shape="square"
            size="small"
            :value="isSelected ? true : isSemiSelected"
            :mark="isSelected ? 'check' : 'minus'"
            :disabled="disabled"
            @click.native.stop
            @input="toggleSelect"
        />
    </div>

    <kk-collapse
        v-else
        class="category"
        :collapsed="!category.open"
        :class="['category', {checked: isSelected}]"
        :style="{marginLeft: (indention > 0 ? 10 : 0) + 'px'}"
    >
        <template #header="{show}">
            <div :class="['title', {open: show}]">
                {{ category[title] }}
                <fa-icon :icon="['fal', 'chevron-down']" />
            </div>
            <kk-checkbox
                shape="square"
                size="small"
                :value="isSelected ? true : isSemiSelected"
                :mark="isSelected ? 'check' : 'minus'"
                :disabled="disabled"
                @click.native.stop
                @input="toggleSelect"
            />
        </template>

        <template v-if="childrenKeyName in category" #content>
            <category
                v-for="(childCategory, index) in category[childrenKeyName]"
                :key="index"
                :title="title"
                :category="childCategory"
                :indention="indention + 1"
                :selected-categories="selectedCategories"
                :semi-selected-categories="semiSelectedCategories"
                :children-key-name="childrenKeyName"
                :disabled="disabled"
            />
        </template>
    </kk-collapse>
</template>

<script>
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { FontAwesomeIcon as faIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import kkCollapse from '../kk-collapse/kk-collapse.vue';
import kkCheckbox from '../kk-checkbox/kk-checkbox.vue';

library.add(faChevronDown);

export default {
    /* eslint-disable */
    name: 'category',
    /* eslint-enable */
    components: {
        kkCollapse,
        kkCheckbox,
        faIcon,
    },
    inject: {
        rootEmit: {
            type: Function,
            default: null,
        },
    },
    props: {
        category: {
            type: Object,
            required: true,
        },
        indention: {
            type: Number,
            default: 0,
        },
        selectedCategories: {
            type: Array,
            default: () => [],
        },
        semiSelectedCategories: {
            type: Array,
            default: () => [],
        },
        childrenKeyName: {
            type: String,
            default: 'children',
        },
        title: {
            type: String,
            default: 'title',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isSelected() {
            return this.selectedCategories.includes(this.category.id);
        },
        isSemiSelected() {
            return this.semiSelectedCategories.includes(this.category.id);
        },
    },
    methods: {
        /**
         * Emits on the root element(kk-categories) input(for v-model) and event for direct hook
         */
        toggleSelect(selected) {
            const id = this.category.id;
            let selectedCopy = JSON.parse(JSON.stringify(this.selectedCategories));

            if (selected) {
                selectedCopy.push(id);

                this.rootEmit('input', selectedCopy);
                this.rootEmit('select', id);
            } else {
                const index = selectedCopy.findIndex(selectedId => selectedId === id);
                selectedCopy.splice(index, 1);

                this.rootEmit('input', selectedCopy);
                this.rootEmit('deselect', id);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.category::v-deep {
    min-height: 0;
    padding: 0;
    box-shadow: none;
    margin-bottom: 1px;
    display: flex;
    align-items: center;

    /* Remove flex from kk-collapse category */
    &.kk-collapse {
        display: block;
        align-items: normal;
    }

    .header-slot {
        display: flex;
        align-items: center;
    }
    .collapse-content {
        margin-top: 1px;
    }
    &.checked > .header-slot .title, &.checked > .title {
        background-color: rgba(10, 174, 243, 0.2);
    }
    .title {
        width: 100%;
        padding: 5px 9px;
        background-color: #F8F8F8;
        border-radius: 1px;
        margin-right: 10px;
        display: flex;
        &.open svg {
            transform: rotate(-180deg);
        }
        svg {
            transition: transform 350ms ease-in-out;
            margin-left: auto;
            margin-right: 5px;
        }
    }
    .kk-checkbox {
        .success.disabled .box.selected {
            background-color: #34d27b !important;
            border: 1px solid #34d27b;
        }
        .box {
            flex: 0 0 19px;
            height: 19px;
            width: 19px;
        }
    }
}
</style>
