<template>
    <!--
        Input event
        @event input
    -->
    <date-picker
        ref="datepicker"
        :attributes="attributesWithHolyDays"
        :class="[
            'kk-datepicker',
            {
                'full-width': fullWidth,
                'active': isActive,
                'only-time': onlyTime,
                'jumping-year-fix': yearPopoverFix,
            },
        ]"
        color="blue"
        is24hr
        :is-range="range"
        :is-required="isRequired"
        :locale="lang"
        :first-day-of-week="2"
        :masks="{
            input: displayFormat || 'DD.MM.YYYY',
            inputDateTime24hr: displayFormat || 'DD.MM.YYYY HH:mm',
        }"
        :max-date="maxDate"
        :min-date="minDate"
        :disabled-dates="disabledDates"
        :mode="mode"
        :model-config="
            returnFormat
                ? {
                    type: 'string',
                    mask: returnFormat,
                }
                : undefined
        "
        :popover="{
            transition: 'none',
            placement: popoverPlacement,
            visibility: 'click',
        }"
        :show-iso-weeknumbers="showWeeks"
        trim-weeks
        :value="value"
        :disabled="disabled"
        @dayclick="handleDayClicked"
        @input="handleInput"
        @popoverDidHide="handlePopoverDidHide"
        @popoverDidShow="isActive = true"
    >
        <template #default="{ inputValue, inputEvents, togglePopover }">
            <!--
                @slot Calendar popup trigger
                @binding {object} All scopes from v-calendar are forwarded
            -->
            <slot name="default" v-bind="{ inputValue, inputEvents, togglePopover }">
                <div class="input-wrapper">
                    <input
                        class="default-calendar-input"
                        :disabled="disabled"
                        :value="range ? rangeValue(inputValue) : inputValue"
                        :placeholder="displayedPlaceholder"
                        v-on="inputEvents"
                        @click="togglePopover"
                    >
                    <!-- @slot Clear button slot -->
                    <slot v-if="clearable && value && !disabled" name="clear-button">
                        <font-awesome-icon
                            class="clear-button"
                            :icon="['fal', 'times-circle']"
                            role="button"
                            :disabled="disabled"
                            @click="$emit('input', null)"
                        />
                    </slot>
                </div>
            </slot>
        </template>
        <template #footer>
            <slot name="footer" v-bind="{ datepicker: $refs.datepicker }">
                <section class="footer">
                    <div v-if="range">
                        <span>{{ trans('shared.Dato periode') }}:</span>
                        <div>
                            <v-select
                                name="date-range"
                                :value="currentPeriodDateRange"
                                :options="dateRangeOptions"
                                :reduce="dateRange => dateRange.id"
                                :clearable="false"
                                :searchable="false"
                                label="title"
                                class="select"
                                :selectable="option => !option.disabled"
                                @input="setRangeValue"
                            />
                        </div>
                    </div>
                    <kk-button :full="fullWidth" @click.prevent="$refs.datepicker.hidePopover()">
                        {{ trans('shared.OK') }}
                    </kk-button>
                </section>
            </slot>
        </template>
    </date-picker>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { DatePicker } from 'v-calendar';
import { getHolyDays } from '../../lib/holy-days';
import kkButton from '../kk-button/kk-button.vue';
import vSelect from 'vue-select';
import { DateRangeOptionTypes, getDateRangeOptions } from '../../../../vue/helpers/date-range-options';

library.add(faTimesCircle);

export default {
    components: {
        DatePicker,
        FontAwesomeIcon,
        kkButton,
        vSelect,
    },
    props: {
        /**
         * v-calendar attributes. Typically used to highlight multiple dates or ranges.
         * @see https://vcalendar.io/date-expressions.html
         */
        attributes: {
            type: Array,
            default: () => [
                {
                    highlight: {
                        color: 'blue',
                        fillMode: 'outline',
                    },
                    dates: new Date(),
                },
            ],
        },

        /**
         * Whether or not to show clear button in the input
         */
        clearable: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not to display time selects in the calendar
         */
        dateTime: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not to display only time select
         */
        onlyTime: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not the calendar popup is disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Date format that is returned to the default slot (trigger input value)
         */
        displayFormat: {
            type: String,
            default: null,
        },

        /**
         * Whether or not to show calendar popup full-width
         */
        fullWidth: {
            type: Boolean,
            default: true,
        },

        /**
         * Whether or not the date is required. Setting to "true" will disable the possibility of deselecting the
         * currently selected date by clicking it again.
         */
        isRequired: {
            type: Boolean,
            default: false,
        },

        /**
         * Disable dates up until this date
         */
        minDate: {
            type: Date,
            default: undefined,
        },

        /**
         * Disable dates after this date
         */
        maxDate: {
            type: Date,
            default: undefined,
        },

        /**
         * Placeholder for trigger input
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * Placement of the popover
         * @see https://vcalendar.io/api/v2.0/datepicker.html#popover-placement
         */
        popoverPlacement: {
            type: String,
            default: undefined,
        },

        /**
         * Date format that is returned in @input
         */
        returnFormat: {
            type: String,
            default: null,
        },

        /**
         * Shows the week number on the left side of the calendar (Boolean)
         * or on a specified side of the calendar (left, left-outside, right, right-outside)
         */
        showWeeks: {
            type: null,
            default: false,
        },

        /**
         * Input value
         */
        value: {
            type: null,
            required: true,
            default: () => null,
        },

        /**
         * Whether or not to enable range selection
         */
        range: {
            type: Boolean,
            default: false,
        },

        /**
         * Whether or not add piece of css that fixes jumping year popover
         */
        yearPopoverFix: {
            type: Boolean,
            default: false,
        },

        /**
         * Maximum range as days for date range picker
         */
        maximumRange: {
            type: Number,
            default: -1,
        },
    },
    data: () => ({
        isActive: false,
        disabledDates: null,
        isFirstDaySelected: false,
        dateRangeOptions: [],
        defaultDateFormat: 'YYYY-MM-DD',
    }),
    computed: {
        currentPeriodDateRange() {
            if (this.range && this.value && this.value.start && this.value.end) {
                const foundDateRangeOption = this.dateRangeOptions.find((option) => {
                    // add return format to all the components
                    // that use this component or define default return format
                    const valueStart = this.returnFormat
                        ? moment(this.value.start, this.returnFormat).format(this.defaultDateFormat)
                        : moment(this.value.start).format(this.defaultDateFormat);
                    const valueEnd = this.returnFormat
                        ? moment(this.value.end, this.returnFormat).format(this.defaultDateFormat)
                        : moment(this.value.end).format(this.defaultDateFormat);

                    return option.start === valueStart && option.end === valueEnd;
                });

                return foundDateRangeOption?.id ?? DateRangeOptionTypes.CUSTOM;
            }

            return DateRangeOptionTypes.CUSTOM;
        },
        attributesWithHolyDays() {
            const today = new Date();
            const threeYearsHolyDaysOfNorway = getHolyDays('NO', [
                today.getFullYear() - 1,
                today.getFullYear(),
                today.getFullYear() + 1,
            ]).map((d) => {
                return {
                    dot: 'red',
                    dates: d.startDate,
                    popover: {
                        label: d.name,
                        labelClass: 'holy-day-popover',
                    },
                };
            });

            return [...threeYearsHolyDaysOfNorway, ...this.attributes];
        },
        lang() {
            return document.documentElement.lang || 'nb';
        },
        mode() {
            if (this.onlyTime) {
                return 'time';
            } else if (this.dateTime) {
                return 'dateTime';
            } else {
                return 'date';
            }
        },
        displayedPlaceholder() {
            if (this.placeholder) {
                return this.placeholder;
            }

            return this.onlyTime ? this.trans('shared.Velg tid') : this.trans('shared.Velg dato');
        },
    },
    mounted() {
        this.dateRangeOptions = getDateRangeOptions(null, null, this.defaultDateFormat);
    },
    methods: {
        setRangeValue(value) {
            const foundDateRangeOption = this.dateRangeOptions.find(o => o.id === value);
            const start = moment(foundDateRangeOption?.start, this.defaultDateFormat);
            const end = moment(foundDateRangeOption?.end, this.defaultDateFormat);

            if (this.returnFormat) {
                this.handleInput({
                    start: start.format(this.returnFormat),
                    end: end.format(this.returnFormat),
                });
            } else {
                // if there is no return format, return Javascript Date Format date like this:
                // "Wed Jan 17 2024 18:51:58 GMT+0300 (GMT+03:00)"
                this.handleInput({
                    start: start.toDate(),
                    end: end.toDate(),
                });
            }

            this.$refs.datepicker.focusDate(start.toDate()); // focus start date when one of the preset selected
        },
        rangeValue(value) {
            return !value.start && !value.end ? null : `${value.start} – ${value.end}`;
        },
        handleDayClicked(event) {
            if (!this.isFirstDaySelected) {
                this.isFirstDaySelected = true;
                const startDate = this.$moment(event.id, 'YYYY-MM-DD')
                    .subtract(this.maximumRange, 'days')
                    .toDate();
                const endDate = this.$moment(event.id, 'YYYY-MM-DD')
                    .add(this.maximumRange, 'days')
                    .toDate();

                if (this.maximumRange > 0) {
                    this.disabledDates = [
                        {
                            start: null,
                            end: startDate,
                        },
                        {
                            start: endDate,
                            end: null,
                        },
                    ];
                }
            }
        },
        handleInput(event) {
            this.$emit('input', event);
        },
        handlePopoverDidHide() {
            this.isActive = false;
            this.isFirstDaySelected = false;
            this.disabledDates = null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables.scss';

@media (max-height: 1079px) {
    ::v-deep .vs__dropdown-menu {
        max-height: 130px;
    }
}

.kk-datepicker::v-deep {
    .vc-container {
        --blue-100: #b6e7fb;
        --blue-200: #85d7f9;
        --blue-300: #54c6f7;
        --blue-400: #2fbaf5;
        --blue-500: #0aaef3;
        --blue-600: #09a7f1;
        --blue-700: #09a7f1;
        --blue-800: #0594ed;
        --blue-900: #154c77;
    }

    input[disabled] {
        cursor: not-allowed;
    }

    .vc-popover-content-wrapper.is-interactive {
        --popover-vertical-content-offset: 10px;

        .vc-title {
            text-transform: capitalize;
        }
    }

    &.full-width {
        // hack to make popover expand parent's full width
        .vc-container {
            min-width: 100%;
        }

        .vc-popover-content-wrapper.is-interactive,
        .vc-popover-content {
            width: 100%;
        }
    }

    &.only-time {
        .vc-date-time {
            .vc-date {
                display: none;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        margin: 5px 0;
        padding: 5px 10px;
        flex-direction: column;
        gap: 20px;
    }

    .default-calendar-input {
        border: 1px solid rgba(60, 60, 60, 0.26);
        border-radius: 4px;
        background: none;
        height: 45px;
        padding: 0 10px;
        width: 100%;

        &[disabled] {
            background-color: var(--input-background-disabled);
            color: var(--text-disabled);
        }
    }

    .input-wrapper {
        position: relative;

        input[disabled] {
            cursor: not-allowed;
        }

        .clear-button {
            box-sizing: content-box;
            color: rgba(60, 60, 60, 0.6);
            cursor: pointer;
            font-size: 16px;
            padding: 5px;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);

            &:hover {
                color: rgba(60, 60, 60, 0.9);
            }

            &[disabled] {
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }

    &.jumping-year-fix {
        position: relative;
        .vc-popover-content-wrapper.is-interactive {
            transform: translate(43px, 38px) !important;
        }
    }
}
</style>
