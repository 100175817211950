export default {
    clear,
    copy,
    beginLine,
    moveLine,
    drawLines,
    getLocalPosition,
    makeCtxWithTransformMatrix,
};

/**
 * Clear every pixel making the canvas transparent.
 *
 * @param {Object} to HTML <canvas>
 */
export function clear(canvas) {
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
}

/**
 * Copy every pixel on one canvas to another
 *
 * @param {Object} to HTML <canvas>
 * @param {Object} from HTML <canvas>
 */
export function copy(to, from) {
    to.width = from.width;
    to.height = from.height;
    let toContext = to.getContext('2d', { alpha: false });
    toContext.drawImage(from, 0, 0);
}

/**
 * Start drawing a line
 *
 * @param {Object} canvas HTML <canvas>
 * @param {Object} localPosition position within the client bounding rectangle
 * @param {String} color
 * @param {Number} lineWidth
 *
 * @return {Object} line. Contains all information canvas needs to draw a line.
 */
export function beginLine(canvas, localPosition, color, rawWidth) {
    let widthScale = _getBoundingRectScale(canvas).width;
    let width = rawWidth / widthScale;

    let ctx = canvas.getContext('2d');
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.beginPath();
    ctx.moveTo(localPosition.x, localPosition.y);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;

    return {
        start: localPosition,
        color,
        width,
        points: [localPosition],
    };
}

/**
 * Draw part of a line. Required that beginLine() already called
 *
 * @param {Object} canvas HTML <canvas>
 * @param {Object} nextPoint {x,y}
 */
export function moveLine(canvas, line, nextPoint) {
    let ctx = canvas.getContext('2d');
    ctx.lineTo(nextPoint.x, nextPoint.y);
    ctx.stroke();
    line.points.push(nextPoint);
}

/**
 * Draw array of lines based on data from line object

 * @param {Object} canvas HTML <canvas>
 * @param {Array} lines line objects
 */
export function drawLines(canvas, lines) {
    let ctx = canvas.getContext('2d');

    for (let i = 0; i < lines.length; ++i) {
        let line = lines[i];
        ctx.beginPath();
        ctx.moveTo(line.start.x, line.start.y);
        ctx.strokeStyle = line.color;
        ctx.lineWidth = line.width;

        let points = line.points;

        for (let j = 0; j < points.length; ++j) {
            ctx.lineTo(points[j].x, points[j].y);
        }
        ctx.stroke();
    }
}



/**
 * Get the relative position of a mouseEvent or touchEvent within the bounding rectangle of the canvas.
 *
 * @param {Object} canvas HTML <canvas>
 * @param {Object} event {clientX, clientY} Could be from mouseEvent or touchEvent
 */
export function getLocalPosition(canvas, { clientX, clientY }) {
    let scale = _getBoundingRectScale(canvas);
    let rect = canvas.getBoundingClientRect();

    return {
        x: Math.floor((clientX - rect.left) / scale.width),
        y: Math.floor((clientY - rect.top) / scale.height),
    };
}

/**
 * Make a canvas context with a baked-in SVG Matrix.
 * This enables the ctx.transformedPoint(point) function, which takes screen
 * coordinates and traslates to context cooridantes, using the Transform Matrix.
 *
 * @param {Object} ctx from canvas.getContext('2d');
 *
 * @return ctx whith extra features
 */
export function makeCtxWithTransformMatrix(ctx) {
    let svg = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
    let xform = svg.createSVGMatrix();


    let savedTransforms = [];
    let save = ctx.save;

    ctx.getTransform = function() {
        return xform;
    };

    ctx.save = function() {
        savedTransforms.push(xform.translate(0, 0));

        return save.call(ctx);
    };

    let restore = ctx.restore;

    ctx.restore = function() {
        xform = savedTransforms.pop();

        return restore.call(ctx);
    };

    let scale = ctx.scale;

    ctx.scale = function(sx, sy) {
        xform = xform.scaleNonUniform(sx, sy);

        return scale.call(ctx, sx, sy);
    };

    let rotate = ctx.rotate;

    ctx.rotate = function(radians) {
        xform = xform.rotate(radians * 180 / Math.PI);

        return rotate.call(ctx, radians);
    };

    let translate = ctx.translate;

    ctx.translate = function(dx, dy) {
        xform = xform.translate(dx, dy);

        return translate.call(ctx, dx, dy);
    };

    let transform = ctx.transform;

    ctx.transform = function(a, b, c, d, e, f) {
        let m2 = svg.createSVGMatrix();
        m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
        xform = xform.multiply(m2);

        return transform.call(ctx, a, b, c, d, e, f);
    };

    let setTransform = ctx.setTransform;

    ctx.setTransform = function(a, b, c, d, e, f) {
        xform.a = a; xform.b = b; xform.c = c; xform.d = d; xform.e = e; xform.f = f;

        return setTransform.call(ctx, a, b, c, d, e, f);
    };

    let pt  = svg.createSVGPoint();

    ctx.transformedPoint = function(x, y) {
        pt.x = x; pt.y = y;

        return pt.matrixTransform(xform.inverse());
    };

    return ctx;
}

/**
 * Returns the scale ratio between canvas and the bounding rectangle.
 * Browser scales the boundingRectangle, while keeping the underlying canvas scale fixed.
 *
 * @param {Object} from HTML <canvas>
 */
function _getBoundingRectScale(from) {
    let rectW = from.getBoundingClientRect().width;
    let rectH = from.getBoundingClientRect().height;

    return { width: rectW / from.width, height: rectH / from.height };
}
