<template>
    <div class="new-contact">
        <div class="form">
            <div>
                <span class="header">
                    <h2>{{ trans('project-creation.Kunde/kontakt') }}</h2>
                    <kk-checkbox
                        size="small"
                        :value="newContactForm.contact_type === 'COMPANY'"
                        @input="newContactForm.contact_type = 'COMPANY'"
                    >
                        {{ trans('contacts.Bedrift') }}
                    </kk-checkbox>
                    <kk-checkbox
                        size="small"
                        :value="newContactForm.contact_type === 'PERSON'"
                        @input="newContactForm.contact_type = 'PERSON'"
                    >
                        {{ trans('contacts.Person') }}
                    </kk-checkbox>
                </span>
                <label @click.prevent>
                    {{ trans('shared.Navn') }}
                    <v-select
                        v-model="newContactForm"
                        class="form-select"
                        taggable
                        label="name"
                        :options="contactOptions"
                        :clearable="false"
                        :create-option="name => ({name})"
                        @option:created="checkForDupeCompany"
                    >
                        <template #option="{ name }">
                            <div class="item-ellipsis" :title="name">
                                {{ name }}
                            </div>
                        </template>
                    </v-select>
                </label>

                <template v-if="! newContactForm.id">
                    <!-- Only show if no existing contact -->
                    <label>
                        {{ trans('shared.Organisasjonsnummer') }}
                        <input
                            v-model="newContactForm.vat_identification_number"
                            :disabled="newContactForm.contact_type !== 'COMPANY'"
                            class="form-input"
                            type="text"
                        >
                    </label>
                    <label @click.prevent>
                        {{ trans('shared.Kategorier') }}
                        <v-select
                            v-model="newContactForm.categories"
                            class="form-select"
                            clearable
                            label="name"
                            :options="categories"
                            :create-option="name => name"
                        />
                    </label>
                    <label>
                        {{ trans('shared.Telefon') }}
                        <div class="input-wrapper">
                            <input v-model="newContactForm.phone" class="form-input" type="number" @input="validationErrors.phone = false">
                            <p v-if="validationErrors.phone" class="error">
                                {{ trans('shared.Må være et gyldig telefonnummer') }}
                            </p>
                        </div>
                    </label>
                    <label>
                        {{ trans('shared.Mobil') }}
                        <div class="input-wrapper">
                            <input v-model="newContactForm.mobile" class="form-input" type="number" @input="validationErrors.mobile = false">
                            <p v-if="validationErrors.mobile" class="error">
                                {{ trans('shared.Må være et gyldig telefonnummer') }}
                            </p>
                        </div>
                    </label>
                    <label>
                        {{ trans('shared.E-post') }}
                        <div class="input-wrapper">
                            <input v-model="newContactForm.email" class="form-input" type="email" @input="validationErrors.email = false">
                            <p v-if="validationErrors.email" class="error">
                                {{ trans('shared.Må være en gyldig e-post') }}
                            </p>
                        </div>
                    </label>
                    <label>
                        {{ trans('info.Adresse') }}
                        <div class="input-wrapper">
                            <input v-model="newContactForm.address_line_1" class="form-input" type="text" @input="validationErrors.address_line_1 = false">
                            <p v-if="validationErrors.address_line_1" class="error">
                                {{ trans('shared.Adresse må fylles inn') }}
                            </p>
                        </div>
                    </label>
                    <label>
                        {{ trans('info.Postnummer') }}
                        <div class="input-wrapper">
                            <input v-model="newContactForm.postal_code" class="form-input" type="text" @input="validationErrors.postal_code = false">
                            <p v-if="validationErrors.postal_code" class="error">
                                {{ trans('shared.Postnummer må fylles ut') }}
                            </p>
                        </div>
                    </label>
                    <label>
                        {{ trans('info.Poststed') }}
                        <div class="input-wrapper">
                            <input v-model="newContactForm.city" class="form-input" type="text" @input="validationErrors.city = false">
                            <p v-if="validationErrors.city" class="error">
                                {{ trans('shared.Poststed må fylles ut') }}
                            </p>
                        </div>
                    </label>
                </template>
            </div>

            <div v-if="newContactForm.name">
                <div class="center-items">
                    <h2>{{ trans('info.Kontaktperson') }}</h2> ({{ trans('shared.valgfri') }})
                </div>
                <label>
                    {{ trans('shared.Fornavn') }} *
                    <input v-model="newContactPersonForm.first_name" class="form-input" type="text">
                </label>
                <label>
                    {{ trans('shared.Etternavn') }} *
                    <input v-model="newContactPersonForm.last_name" class="form-input" type="text">
                </label>
                <label>
                    {{ trans('shared.Telefon') }}
                    <div class="input-wrapper">
                        <input v-model="newContactPersonForm.phone" class="form-input" type="number" @input="validationErrors.personPhone = false">
                        <p v-if="validationErrors.personPhone" class="error">
                            {{ trans('shared.Må være et gyldig telefonnummer') }}
                        </p>
                    </div>
                </label>
                <label>
                    {{ trans('shared.Mobil') }}
                    <div class="input-wrapper">
                        <input v-model="newContactPersonForm.mobile" class="form-input" type="number" @input="validationErrors.personMobile = false">
                        <p v-if="validationErrors.personMobile" class="error">
                            {{ trans('shared.Må være et gyldig telefonnummer') }}
                        </p>
                    </div>
                </label>
                <label>
                    {{ trans('shared.E-post') }}
                    <div class="input-wrapper">
                        <input v-model="newContactPersonForm.email" class="form-input" type="email" @input="validationErrors.personEmail = false">
                        <p v-if="validationErrors.personEmail" class="error">
                            {{ trans('shared.Må være en gyldig e-post') }}
                        </p>
                    </div>
                </label>
                <label>
                    {{ trans('shared.Stilling') }}
                    <input v-model="newContactPersonForm.position" class="form-input" type="text">
                </label>
                * = {{ trans('shared.Kreves') }}
            </div>
        </div>

        <div class="action-buttons">
            <kk-button type="basic" @click="$emit('open-creation', false)">
                {{ trans('shared.Avbryt') }}
            </kk-button>
            <kk-button
                :disabled="disableSaveContactButton || disableButton"
                @click="saveContact"
            >
                {{ trans('shared.Legg til') }}
            </kk-button>
        </div>
    </div>
</template>

<script>
import { trans } from "../../../mixin/trans";

import kkCheckbox from '../../kk-checkbox/kk-checkbox.vue';
import kkButton from '../../kk-button/kk-button.vue';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const defaultValidationErrors = () => ({
    phone: false,
    mobile: false,
    email: false,
    postal_code: false,
    city: false,
    personPhone: false,
    personMobile: false,
    personEmail: false,
});

const emptyContact = () => ({
    name: "",
    contact_type: "COMPANY",
    vat_identification_number: '',
    phone: '',
    mobile: '',
    email: '',
    categories: [],
    address_line_1: '',
    postal_code: '',
    city: '',
    persons: [],
    is_vendor: false,
    is_customer: true,
});

const emptyPerson = () => ({
    first_name: '',
    last_name: '',
    phone: '',
    mobile: '',
    email: '',
    position: '',
});

export default {
    name: "new-contact",
    components: {
        kkCheckbox,
        kkButton,
        vSelect,
    },
    mixins: [trans],
    props: {
        contactOptions: {
            type: Array,
            default: () => [],
        },
        categories: {
            type: Array,
            default: () => [],
        },
        disableButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            newContactForm: null,
            newContactPersonForm: null,
            validationErrors: defaultValidationErrors(),
        };
    },
    computed: {
        disableSaveContactButton() {
            return this.newContactForm.name.length === 0;
        },
        shouldSendPerson() {
            return this.newContactPersonForm.first_name.length > 0
                && this.newContactPersonForm.last_name.length > 0;
        },
    },
    created() {
        this.emptyForm();
    },
    methods: {
        emptyForm() {
            this.newContactForm = emptyContact();
            this.newContactPersonForm = emptyPerson();
        },
        checkForDupeCompany({name}) {
            // Store current info.
            const currentContact = this.newContactForm;

            if (name) {
                const alreadyExists = this.contactOptions.find(contact => {
                    return String(contact.name).toLowerCase() === String(name).toLowerCase();
                });

                if (alreadyExists) {
                    // Hack to bypass @input event from overwriting our data. @input triggeres after @option:created
                    setTimeout(() => {
                        this.newContactForm.firm = alreadyExists;
                    }, 100);
                } else {
                    setTimeout(() => {
                        this.newContactForm = {
                            ...currentContact,
                            name,
                        };
                    }, 100);
                }
            }
        },
        /**
         * Validate Phone Number
         */
        validatePhone(phone) {
            const validRegex = /^[+ 0-9]*$/;

            return phone.match(validRegex);
        },
        /**
         * Validate Email
         */
        validateEmail(email) {
            const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            return email.match(validRegex);
        },
        resetValidationErrors() {
            this.validationErrors = defaultValidationErrors();
        },
        validateForm(contact, shouldValidatePerson, contactPerson) {
            // Reset previous errors
            this.resetValidationErrors();

            if (contact.phone?.length > 0 && !this.validatePhone(contact.phone)) {
                this.validationErrors.phone = true;
            }

            if (contact.mobile?.length > 0 && !this.validatePhone(contact.mobile)) {
                this.validationErrors.mobile = true;
            }

            if (contact.email?.length > 0 && !this.validateEmail(contact.email)) {
                this.validationErrors.email = true;
            }

            if (contact.address_line_1 && !(contact.postal_code && contact.city)) {
                if (!contact.postal_code) {
                    this.validationErrors.postal_code = true;
                }

                if (!contact.city) {
                    this.validationErrors.city = true;
                }
            }

            if ((contact.postal_code || contact.city) && !(contact.postal_code && contact.city)) {
                if (!contact.postal_code) {
                    this.validationErrors.postal_code = true;
                }

                if (!contact.city) {
                    this.validationErrors.city = true;
                }
            }

            if (shouldValidatePerson) {
                if (contactPerson.phone?.length > 0 && !this.validatePhone(contactPerson.phone)) {
                    this.validationErrors.personPhone = true;
                }

                if (contactPerson.mobile?.length > 0 && !this.validatePhone(contactPerson.mobile)) {
                    this.validationErrors.personMobile = true;
                }

                if (contactPerson.email?.length > 0 && !this.validateEmail(contactPerson.email)) {
                    this.validationErrors.personEmail = true;
                }
            }

            if (Object.values(this.validationErrors).some(error => error)) {
                return false;
            }

            return true;
        },
        saveContact() {
            this.$emit('activate-spinner', true);
            // Validate email & phone if the values exists
            const validated = this.validateForm(this.newContactForm, this.shouldSendPerson, this.newContactPersonForm);

            if (!validated) {
                this.$emit('activate-spinner', false);

                return;
            }
            this.$emit('new-contact', {
                contact: this.newContactForm,
                contactPerson: this.shouldSendPerson ? this.newContactPersonForm : null,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$disabledGrey: #ddd;
* {
    box-sizing: border-box;
}
.new-contact {
    display: flex;
    flex-direction: column;
    flex: 1;
    .header {
        display: flex;
        gap: 10px;
        align-items: center;
        .kk-checkbox::v-deep .label-content {
            margin-bottom: unset;
        }
    }
    .form::v-deep {
        flex: 1;
        display: flex;
        max-height: 400px;
        overflow: auto;

        > div:first-of-type {
            margin-right: 20px;
        }

        .center-items {
            display: flex;
            align-items: center;
            gap: 5px;
        }

        label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 7px;
            .form-input {
                position: relative;
                z-index: 2; /** Hack to not make v-select input overflow next label-input */
                width: 180px;
                display: block;
                padding: 12px 8px;
                border-radius: 3px;
                box-sizing: border-box;
                border: 1px solid #D6D5D5;
                height: 30px;
                margin-left: 10px;
                font-size: inherit;
                &:disabled {
                    background: $disabledGrey;
                }
            }
            .form-select {
                width: 180px;
                height: 30px;
                margin-left: 10px;

                .item-ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .vs__dropdown-toggle {
                    height: 30px;
                    justify-content: space-around;
                }

                .vs__selected-options {
                    max-width: calc(100% - 40px);
                }

                .vs__selected {
                    display: block;
                    max-width: 100%;
                    line-height: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .vs__search {
                    font-size: 1em !important;
                }

                .vs__dropdown-menu {
                    max-height: 235px;
                    border-radius: 0;
                }
            }
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
        }

        h2 {
            font-weight: 500;
            line-height: 1.1;
        }

        .kk-checkbox {
            margin: 0 !important;
        }
    }
    .action-buttons {
        display: flex;
        justify-content: space-around;
        padding-top: 10px;
    }
    .error {
        color: red;
        margin-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
        width: 180px;
        line-height: 1.4;
    }
}

.mobile {
    .new-contact {
        .form {
            flex-direction: column;
        }
        .form::v-deep {
            max-height: unset;
            min-height: 400px;

            > div:first-of-type {
                margin-right: 0;
            }
        }
    }
}
</style>
