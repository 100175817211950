import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons/faTimes";
import { faDownload } from "@fortawesome/pro-light-svg-icons/faDownload";
import { faMagnifyingGlassPlus } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlassPlus";
import { faMagnifyingGlassMinus } from "@fortawesome/pro-light-svg-icons/faMagnifyingGlassMinus";
library.add(faTimes, faDownload, faMagnifyingGlassPlus, faMagnifyingGlassMinus);

import { trans } from "../../mixin/trans";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

// @vue/component
export default {
    name: "kk-pdf-viewer",
    components: {
        FontAwesomeIcon,
        VuePdfEmbed,
    },
    mixins: [trans],
    props: {
        file: {
            type: Object,
            required: true,
            default: () => {},
        },
        showAllPages: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isLoading: true,
            isVisualizeUpdating: false,
            page: null,
            pageCount: 1,
            scale: 1,
            scaleConfig: {
                max: 3,
                min: 0.1,
                increaseLevel: 0.1,
            },
        };
    },

    computed: {
        pdfSource() {
            return this.file?.src ?? null;
        },
        vFilename() {
            return this.file?.name ?? "";
        },
    },

    watch: {
        showAllPages() {
            this.page = this.showAllPages ? null : 1;
        },
    },

    methods: {
        zoomIn() {
            if (this.scaleConfig.max > this.scale) {
                this.isVisualizeUpdating = true;
                this.scale += this.scaleConfig.increaseLevel;
                this.isVisualizeUpdating = false;
            }
        },
        zoomOut() {
            if (this.scaleConfig.min < this.scale) {
                this.isVisualizeUpdating = true;
                this.scale -= this.scaleConfig.increaseLevel;
                this.isVisualizeUpdating = false;
            }
        },
        handleDocumentRender() {
            this.isLoading = false;
            this.pageCount = this.$refs.pdfRef.pageCount;
        },
        hide() {
            this.$emit("hide");
        },
    },
};
